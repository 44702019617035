@import "./../config.scss";

.e_indices_modal {
  .e_modal_dialog {
    max-width: 306px;
    height: 332px;
  }
  .e_modal_content {
    border-radius: 16px;
    box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
    h5 {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: $white;
    }
    
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #7d7688;
  }
  .e_modal_body {
    padding: 0 25px!important;
  }
  .e_checl_mark{
    margin-top: 2px;
  }
}
