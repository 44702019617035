/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 25-01-2022
 */

@import "./../config.scss";

.e_tab {
	@extend %font24;
	font-family: $font-secondary;
	font-weight: 800;
	line-height: 120%;
	color: rgba(255, 255, 255, 0.4);
	transition: ease 0.3s;
	padding-bottom: 8px;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		width: 0;
		height: 3px;
		background-color: $primary_color;
		bottom: 0;
		transition: all 0.35s;
	}
	&:hover {
		&:before {
			width: 100%;
		}
		cursor: pointer;
		opacity: 1;
	}
}
.e_active_tab {
	@extend .e_tab;
	transition: ease 0.3s;
	opacity: 1;
	color: $white;
	font-weight: 800;
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 3px;
		background-color: $primary_color;
		bottom: 0px;
		left: 0;
	}
}
