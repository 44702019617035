
@import './../config.scss';

.e_overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #130727;
    z-index: 9999;
    top: 0;
    left: 0;
    // opacity: 0.7;
    background-color: $secondary_dark;
        .e_loader_wrap{
            opacity: 1!important;
            margin: auto;
            top: 39%;
            width: 200px;
            height: 80px;
            position: relative;
            text-align: center;
            img{
                width: 100px;
                height: 100px;
            }
            h6{
                // animation: 1s infinite alternate coolrotate
            }
        }
}

@keyframes coolrotate {
    from {
      transform: scale(1, 1) translate(-0.1em, 0)
    }
    to {
      transform: scale(-1, 1) translate(0, 0)
    }
  }