@import "./config.scss";

/*-------------Light theme--------*/
.light {
  background-color: #edf0ff;
  .overlay_e_overlay__BeZ9B,
  .e-overlay,
  .e-loader,
  .loader_e_loader__LPc6n {
    background-color: #edf0ff;
  }
  .e-skill-race-container .e-activities-warpper {
    .e-red {
      color: #fa6363 !important;
      font-weight: 500 !important;
    }

    .e-green {
      color: #008d41 !important;
      font-weight: 500 !important;
    }
  }

  .e-red {
    color: #fa6363 !important;
    font-weight: 500 !important;
  }

  .e-green {
    color: #008d41 !important;
    font-weight: 500 !important;
  }
  .e-green-bg {
    background-color: #1f3b22 !important;
  }

  .e-red-bg {
    background-color: #5a1b1e !important;
  }
  .e-body {
    padding-top: 8.5rem;
    background: #edf0ff;
  }
  .accordion-body {
    margin: 10px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    -webkit-text-fill-color: #1f1136 !important;
  }
  .e-header-conatiner,
  .e-game-dropdown,
  .e-header-notification-wraper,
  .e-profile-dropdown,
  .e-home-card-wrapper,
  .e-side-wrap,
  .e-h-card-wrapper,
  .e-select-wraper .css-1s2u09g-control,
  .e-select-wraper .css-1pahdxg-control,
  .e-select-wraper .css-26l3qy-menu div,
  .e-result-filter-wrap .e-select-wraper .css-1s2u09g-control,
  .e-result-filter-wrap .e-select-wraper .css-1pahdxg-control,
  .e-select-gender .css-1pahdxg-control:hover,
  .accordion-body,
  .accordion .accordion-item,
  .e-reports-filter-wrapper .e-select-wraper .css-1s2u09g-control,
  .e-reports-filter-wrapper .e-select-wraper .css-1pahdxg-control,
  .ant-picker,
  .e-uploader,
  .rdt_TableRow,
  .rdt_TableHeadRow,
  .rdt_Table,
  .rdt_TableCol,
  .kyc_e_kyc_modal__vXhCI .kyc_e_modal_content__6oWtM .kyc_e_input_bg__w0ici input,
  .e-skill-race-container .e-remove-card,
  .e-skill-race-container .e-preferred-wraper,
  .e-table-bg,
  .e-activity-layout,
  .e-skill-race-container .e-scrip-search,
  .e-scrip-search,
  .e-skill-race-container .e-search-wraper,
  .slotCheck_e_slot_modal__LleOU,
  .slotCheck_e_slot_modal__8co9K,
  .e-slot-modal,
  .e-input-box,
  .input_e_input_box__gI-fV,
  .input_e_input_box__PE4Ml,
  .e-filter-card,
  .e-news-card,
  .e-performance-wrapper,
  .e-scrip-card {
    background-color: $white !important;
  }
  .rdt_TableHeadRow {
    border-bottom-color: #c0c0c0 !important;
  }
  .e-skillrace-table-header {
    border-bottom: 1px solid #c0c0c0 !important;
  }
  .e-table-border-bottom {
    border-bottom: 1px solid #e9ebfd !important;
  }
  .jVeafb:not(:last-of-type),
  .sc-jqUVSM:not(:last-of-type) {
    border-bottom-color: #e9ebfd !important;
  }
  .e-select-wraper .css-1s2u09g-control,
  .e-select-wraper .css-1pahdxg-control {
    border: 1px solid #cacaca !important;
  }
  .e-result-filter-wrap .e-select-wraper .css-1s2u09g-control,
  .e-result-filter-wrap .e-select-wraper .css-1pahdxg-control,
  .e-reports-filter-wrapper .e-select-wraper .css-1s2u09g-control,
  .e-reports-filter-wrapper .e-select-wraper .css-1pahdxg-control {
    border: 1px solid #cacaca !important;
  }
  .kyc_e_kyc_modal__c9Ix5 .kyc_e_modal_content__L-iEk .kyc_e_input_bg__asIK3,
  .kyc_e_kyc_modal__c9Ix5 .kyc_e_modal_content__L-iEk .kyc_e_input_bg__asIK3 input,
  .e-search-wraper,
  .e-profile-container .e-wrap-mob {
    background: $white !important;
  }
  .e-total {
    background-color: transparent;
  }
  .e-skill-race-container .e-scrip-search,
  .e-scrip-search {
    border: none !important;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
    color: $secondary_color;
  }
  .e-v2r-scrip-search {
    box-shadow: none !important;
  }
  .e-dashboard-range {
    position: relative;
    background-image: url("../assets/images/dropdown/side-black.svg") !important;
    background-repeat: no-repeat;
    background-position: 95% center;
  }
  .e-dashboard-range .e-ant-daterange-picker::before {
    content: "Date range";
    position: absolute;
    color: $secondary_color;
  }
  .e-dashboard-range .ant-picker-suffix,
  .e-dashboard-range .ant-picker-range-separator {
    display: none !important;
  }
  .e-dashboard-range .ant-picker {
    border: none !important;
    padding: 10px 10px 0px 10px !important;
    background: transparent !important;
    &:hover {
      border: none !important;
    }
  }
  .e-v2r-container .e-order-section .e-table-action,
  .e-watchlist-tabs .e-v2r-active-tab,
  .e-v2r-container .e-v2r-tabs .e-active-tab,
  .e-action-buy,
  .e-action-sell {
    // background-color: $primary_color !important;
    color: $white !important;
  }
  .e-skill-race-container .e-end-time,
  .e-remove-card,
  .e-scrip-voting-wrapper,
  .e-v2r-container .e-card-wrappper,
  .e-market-section-card,
  .e-summary-card,
  .e-v2r-container .e-16rounded-table .sc-dmRaPn,
  .e-v2r-container .e-participants .e-end-time,
  .e-v2r-container .e-v2r-tabs .e-inactive-tab,
  .e-v2r-container .rdt_TableHeadRow,
  .e-sort-section,
  .e-sort-holdings-section,
  .e-welcome-content-wrapper,
  .welcome_e_welcome_modal__n4rBm .welcome_e_modal_content__4fzIi .welcome_e_content_wrapper__ssTOM,
  .e-table-bg {
    background-color: $white !important;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
    span {
      color: #1f1136;
    }
  }

  .e-v2r-tab {
    border: solid 1px #d5daf3;
    box-shadow: none;
    background-color: transparent !important;
  }

  .welcome_e_welcome_modal__n4rBm .welcome_e_modal_content__4fzIi .welcome_e_content_wrapper__ssTOM,
  .e-welcome-content-wrapper {
    p,
    ol,
    li {
      color: #1f1136 !important;
    }
  }

  .e-scrip-detail p,
  .e-scrip-card h6 {
    color: #1f1136 !important;
  }
  .e-dashboard-select .e-show-dropdown .e-arrow-black-show {
    background-repeat: no-repeat;
    background-position: 95% center;
    background-image: url("../assets/images/dropdown/side-black.svg");
  }
  .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H,
  // .iheader_e_header_mobile_header__ZywWY,
  .e-mob-header-show,
  .kyc_e_kyc_modal__vXhCI .kyc_e_modal_content__6oWtM .kyc_e_input_bg__w0ici,
  .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H input,
  .rdt_Table,
  .PhoneInput,
  .PhoneInput input,
  .e-m2e-gainerloser-card,
  .e-skill-race-table,
  .e-card-light,
  .e-show-dropdown,
  .e-second-show-dropdown,
  .date-picker-wrapper,
  .e-v2r-container .rdt_TableCol,
  .e-header-btn-bg,
  .e-login-r-wrapper {
    background-color: $white !important;
  }
  .e-dashboard-select .e-dashbaord-dropdown {
    background-image: url("../assets/images/dropdown/arrow-down-black.svg");
  }
  .sc-kYWVYA,
  .sc-dUWWNf,
  .sc-iWajrY {
    color: $secondary_color;
  }
  .checkBox_e_checkbox_container__\+KTKE .checkBox_checkmark__tnkxf,
  .radioButton_e_radiobutton__h3wo6 label:before,
  .e-v2r-radio-btn label:before {
    border: 1px solid $secondary_color;
  }
  .buyStock_e_buy_stock_modal__I5iCe .buyStock_e_modal_content__BbOwy .buyStock_e_disabled_input__x\+AfH,
  .buyStock_e_buy_stock_modal__ZmdXA .buyStock_e_modal_content__simTt .buyStock_e_disabled_input__htHs7,
  .e-disabled-input {
    background-color: #eae9ef !important;
    border: 1px solid $footer_bg_light;
  }
  .input_e_input_box__gI-fV,
  .input_e_input_box__PE4Ml,
  .e-input-box {
    border: none;
    color: #1f1136 !important;
    border: 1px solid #cacaca !important;
    background: transparent;
    &:focus {
      border: 1px solid #cacaca !important;
    }
  }
  .e-game-detail-wrapper .e-cancel-btn,
  .e-low-balance,
  .buyStock_e_buy_stock_modal__I5iCe .buyStock_e_modal_content__BbOwy .buyStock_e_toggle_label_false__fOcSr,
  .e-toggle-v2r,
  .e-toggle-v2r-false,
  .buyStock_e_buy_stock_modal__I5iCe .buyStock_e_modal_content__BbOwy .buyStock_e_toggle_label__SApv2 {
    color: #1f1136 !important;
  }
  .e-span-dark,
  .e-header-link,
  .e-wallet-balance,
  .e-game-dropdown span,
  .e-notification-description,
  .e-profile-dropdown span,
  .e-profile-dropdown h4,
  .e-secondary-color,
  .e-v2r-container .e-summary-section .e-summary-card .e-pie-label-title,
  .e-v2r-container .e-bread-crumb,
  .e-side-wrap h5,
  .e-profile-container .e-game-dashboard .e-earnings-amount,
  .e-select-wraper .css-qc6sy-singleValue,
  .e-select-wraper .css-tlfecz-indicatorContainer,
  .e-select-wraper .css-1gtu0rj-indicatorContainer,
  .e-select-wraper .css-14el2xx-placeholder,
  .e-select-wraper .css-26l3qy-menu div,
  .ant-picker-header,
  .ant-picker-content th,
  .ant-picker-cell-in-view,
  .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H,
  .kyc_e_kyc_modal__vXhCI .kyc_e_modal_content__6oWtM .kyc_e_input_bg__w0ici,
  .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H input,
  .e-uploader,
  .rdt_TableCell,
  .kyc_e_kyc_modal__vXhCI .kyc_e_modal_content__6oWtM .kyc_e_input_bg__w0ici input,
  .modal-header h4,
  .PhoneInput input,
  .checkBox_e_checkbox_container__3Ttss,
  .checkBox_e_checkbox_container__NPhUn,
  .e-game-modal-content p,
  .e-game-modal-content ol > li,
  .e-game-modal-content ul > li,
  .e-profile-container .e-location,
  .ant-picker-input > input,
  .e_table_input,
  .e-edit-modal-symbol,
  .e-empty-table,
  .e-skillrace-table-body,
  .e-skill-race-container .e-remove-card span,
  .e-bookings,
  .e-table-header,
  .e-total-added,
  .e-skill-race-container .e-activities-warpper p,
  .e-buy-v2r .modal-content label,
  .e-buy-v2r .modal-content input,
  .e-buy-v2r .modal-content .buyStock_e_toggle_label_false__1tepK,
  .e-buy-v2r .modal-content .buyStock_e_toggle_label__HW8yV,
  .e-skill-race-container .radioButton_e_radiobutton__XQMbA label,
  .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD .buyStock_e_input__aWO1U,
  .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD .buyStock_e_input__aWO1U,
  .e-skill-race-container .e-activities-warpper .e-icon,
  .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD .buyStock_e_toggle_label_false__1tepK,
  .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD .buyStock_e_toggle_label__HW8yV,
  .buyStock_e_buy_stock_modal__ZmdXA .buyStock_e_modal_content__simTt .buyStock_e_toggle_label__rPHc8,
  .buyStock_e_buy_stock_modal__ZmdXA .buyStock_e_modal_content__simTt .buyStock_e_toggle_label_false__RvyxM,
  .radioButton_e_radiobutton__XQMbA label,
  .radioButton_e_radiobutton__UO7wN label,
  .input_e_input_box__2pgsO,
  .iheader_e_toggle_label__8ERj4,
  .iheader_e_toggle_label__WELVz,
  .e-toggle-label,
  .secondary_e_s_w_btn__9eb\+1,
  .e-secondary-btn,
  label {
    color: $secondary_color !important; // #1f1136
  }
  .sc-crXcEl:hover {
    opacity: 0.9;
  }
  .radioButton_e_radiobutton__XQMbA label:before,
  .radioButton_e_radiobutton__UO7wN label:before,
  .secondary_e_s_w_btn__9eb\+1,
  .e-secondary-btn {
    border: 1px solid $secondary_color;
  }
  .e-game-dropdown span:hover,
  .e-profile-dropdown span:hover,
  .e-skill-race-container .e-watchlist-avg .e-text-btn-active {
    color: $primary_color !important; //#9c1fe9
  }
  .e-skill-race-container .e-tutorial a {
    border-bottom: 1.5px solid $secondary_color;
  }
  .e-result-btn:hover {
    box-shadow: none !important;
  }

  .e-footer-conatiner {
    background-color: $white;
  }
  .sc-dmRaPn,
  .e-skill-race-table,
  .e-preferred-wraper,
  .e-card-light,
  .e-news-card,
  .e-scrip-card {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16) !important;
  }
  .e-v2r-container .e-scrip-card {
    box-shadow: none !important;
  }
  .e-light-wraper,
  .accordion-header,
  .ant-picker-panel-container .ant-picker-panels,
  .modal-content,
  .ant-picker-panel-container,
  .e-scrip-fixed .e-submit-scrip-sec,
  .e-skill-race-container .e-activities-warpper,
  .e-skill-race-container .e-selected-item,
  .e-profile-card {
    background-color: $light_theme_bg !important; //#fafafa;
  }
  .e-game-detail-modal .modal-content {
    background-color: #edf0ff !important ;
  }
  .e-v2r-item:hover {
    background-color: $light_theme_bg;
    border: solid 1px #2b213a !important;
  }
  .e-v2r-selected-item:hover {
    border-top: solid 1px #2b213a;
  }
  .e-v2r-container .e-v2r-item,
  .e-v2r-container .e-v2r-selected-item {
    background-color: $light_theme_bg !important; //#fafafa;
  }
  .checkBox_e_checkbox_container__NPhUn .checkBox_checkmark__TyWzs,
  .checkBox_e_checkbox_container__3Ttss .checkBox_checkmark__JST4W {
    border: 1.5px solid $secondary_color;
  }
  .e-notification-hover:hover {
    background-color: #eae9ef !important;
  }
  .ant-picker-cell .ant-picker-cell-inner:hover {
    color: $white;
  }
  .e-header-notification-wraper,
  .e-home-card-wrapper,
  .e-profile-card,
  .e-side-wrap,
  .accordion-item,
  .e-m2e-gainerloser-card,
  .e-skill-race-container .e-remove-card,
  .e-activity-layout {
    box-shadow: 0 2px 24px rgb(0 0 0 / 16%) !important;
  }
  .PhoneInput {
    border: 1px solid #cacaca !important;
  }

  ::-webkit-scrollbar {
    border-radius: 8px;
    height: 4px;
    width: 4px;
  }
  .e-booking-table {
    .sc-dmRaPn {
      border-radius: 16px !important;
    }

    .rdt_TableRow {
      border-bottom: 1px solid #e9ebfd !important;
      padding: 10px 0;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #edf0ff;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background: transparent;
  }
  .e-topper-card {
    background-image: none;
  }

  .e-game-result-modal .e-active-tab {
    color: $primary_color !important;
  }
  .e-progress-base {
    background: $secondary_color;
  }

  .e-footer-link-color,
  .e-link-colors,
  .e-footer-link p,
  .e-copy-right {
    color: #4b4b4b !important;
    font-weight: 500 !important;
  }
  .e-secondary-btn {
    &:hover {
      color: $primary_color !important;
      border: 1px solid $primary_color;
    }
  }
  .e-v2r-cancel {
    &:hover {
      color: #7d7688 !important;
      border-top: none;
      border-right: none;
      border-left: none;
    }
  }
  .e-select-wraper .css-26l3qy-menu div:hover {
    color: $primary_color !important;
  }
  .accordion-item,
  .ant-picker:hover,
  .ant-picker-focused,
  .ant-picker {
    border: 1px solid #cacaca !important;
  }
  .e-arr-down {
    background-image: url("../assets/images/other/down-dark.svg") !important;
    background-size: 28px;
  }
  .e-arr-down-faq {
    background-image: url("../assets/images/other/down-dark.svg") !important;
    background-size: 18px;
  }
  .accordion .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }
  .e-span-disable {
    color: $white;
  }
  .e-secondary-color-opacity {
    color: $secondary_color;
    opacity: 0.7;
  }

  //faq acc
  .e-faq-accordion .accordion .accordion-item,
  .e-faq-accordion .accordion-header,
  .e-faq-accordion .e-acc-btn {
    background-color: transparent !important;
  }
  .e-faq-accordion .accordion-item {
    box-shadow: unset !important;
    border: none !important;
  }
  .e-faq-accordion .e-acc-wraper {
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 8px;
    color: $secondary_color;
  }
  .e-faq-accordion .accordion-body {
    background-color: transparent !important;
  }
  .e-agent-wrap,
  .e-wallet-wraper,
  .e-v2r-container .e-action-depth,
  .e-action,
  .e-mobile-hand-burger-menu {
    background-color: #edf0ff !important;
    border: solid 1px #d5daf3 !important;
  }

  .e-span {
    background-color: #f7f6ff !important;
    border: solid 1px #bdbdbd !important;
  }

  .ant-picker-clear {
    background-color: #ffffff !important;
  }
  // .e-topics {
  //   font-size: 13px;
  //   background-image: url("../assets/images/game-detail/more-back.svg") !important;
  //   background-repeat: no-repeat !important;
  //   background-position: 10px 50% !important;
  //   cursor: grab !important;
  // }
  .e-border-bottom {
    border-bottom: 1px solid #aeaeae;
  }
  .e-side-tab {
    color: $secondary_color;
    &:hover {
      color: $white;
    }
  }

  // .faqAccordion_e_accordion_item__iAkKb.accordion-item h2.accordion-header {
  //   background-color: transparent !important;
  // }
  // .iheader_e_header__bjleM,
  // .iheader_e_header_mobile_header__HLaHW,
  // .iheader_e_header__bjleM .iheader_e_header_ul__5oTNQ .iheader_e_ac_balance__vxefc,
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3,
  // .homeEarnings_e_card_wrapper__\+XYh0,
  // .home-summary_e_summary_card__bt1sw,
  // .card.e-topper-card,
  // .letter-board_e_leader_card__7jdw5,
  // .e-profile-container .e-side-wrap,
  // .e-profile-container .e-tab-content,
  // .summary_e_summary_card__YC1UZ,
  // .accordion .accordion-item:not(.faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_item__iAkKb),
  // .e-reports-filter-wrapper .e-select-wraper .css-1s2u09g-control,
  // .css-1pahdxg-control,
  // .css-1pahdxg-control:hover,
  // .e-select-wraper .css-26l3qy-menu div,
  // .ant-picker,
  // .refer_e_refer_card__0yVBP,
  // .game-type_e_game_type_modal__DFkwT,
  // .game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4 .game-detail_e_content_wrapper__fGJUm,
  // .gameSummary_e_game_summary_card__phAbR,
  // .rdt_TableRow,
  // .e-select-wraper .css-1s2u09g-control,
  // .rdt_TableHeadRow,
  // .rdt_Table,
  // .rdt_TableCol,
  // .gainers-losers_e_gainers_losers_card__x5FLy,
  // .game-result_e_game_result_modal__LtFbS .game-result_e_modal_content__p5leW .game-result_e_result_table__9aJnm,
  // .e-span,
  // .e-skill-race-container .e-preferred-wraper,
  // .e-skill-race-container .e-end-time,
  // .profile_e_menu_dropdown__jD0XD .profile_e_logout_div__UcMA-,
  // .e-skill-race-container .e-scrip-search,
  // .e-skill-race-container .search_e_search_wrapper__8i3JA,
  // .e-skill-race-table,
  // .customizeTable_e_customize_modal__LF3uv .customizeTable_e_modal_content__m0J2J.modal-content,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_edit_watchlist_wraper__F5NVL,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_edit_watchlist_input__JEPwf,
  // .e-404-page,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_profile_card__90SQ\+,
  // .input_e_input_box__2pgsO,
  // .PhoneInput,
  // .PhoneInput input,
  // .e-amount-card,
  // .e-wallet-card,
  // .e-result-filter-wrap .e-select-wraper .css-1s2u09g-control,
  // .e-result-filter-wrap .e-select-wraper .css-1pahdxg-control,
  // .e-reports-filter-wrapper .e-select-wraper .css-1pahdxg-control,
  // .e-select-wraper .css-1s2u09g-control,
  // .game-type_e_game_type_modal_home__DkGXj,
  // .e-uploader,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H input,
  // .notificationList_e_notification_card__cBaHI,
  // .game-result_e_game_result_modal__LtFbS .game-result_e_total_qty__F765x,
  // .e-activity-layout,
  // .e-mobile-hand-burger-menu,
  // .iheader_e_header_mobile_header__HLaHW .iheader_e_agent_wrap__bAOS3,
  // .scrip-card_e_card__ETPJd,
  // .e-filter-card,
  // .e-scrip-voting-wrapper,
  // .search_e_search_box__Eq0CO,
  // .e-skill-race-container .e-remove-card,
  // .e-profile-container .e-bank-wraper .e-more-dropdown ,
  // .preference_e_preference_modal__w-s5j .preference_e_bank_card__OcCTs {
  //   background-color: $white !important;
  // }
  // .iheader_e_header__bjleM .iheader_e_header_ul__5oTNQ .iheader_e_header_link__8bqlG,
  // .iheader_e_header_mobile_header__HLaHW .iheader_e_header_ul__5oTNQ .iheader_e_header_link__8bqlG,
  // .iheader_e_header__bjleM .iheader_e_header_ul__5oTNQ .iheader_e_ac_balance__vxefc,
  // .iheader_e_header_mobile_header__HLaHW .iheader_e_header_ul__5oTNQ .iheader_e_ac_balance__vxefc,
  // .iheader_e_header__bjleM .iheader_e_header_ul__5oTNQ .iheader_e_ac_balance__vxefc,
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3,
  // .homeEarnings_e_card_wrapper__\+XYh0 .homeEarnings_e_intro__Q2tDA,
  // .homeEarnings_e_card_wrapper__\+XYh0 .homeEarnings_e_user_name__eIrbT,
  // .homeEarnings_e_card_wrapper__\+XYh0 .homeEarnings_e_user_rank__aIoaM,
  // .homeEarnings_e_card_wrapper__\+XYh0 .homeEarnings_e_amount__7XXFq,
  // .homeEarnings_e_card_wrapper__\+XYh0 .homeEarnings_e_game__4lHMH,
  // .homeEarnings_e_card_wrapper__\+XYh0 .homeEarnings_e_reward__zwd-3,
  // .home-summary_e_summary_card__bt1sw .home-summary_e_summary__iHnBd,
  // .home-summary_e_summary_card__bt1sw .home-summary_e_score__w\+rnm,
  // .home-summary_e_summary_card__bt1sw,
  // .home-summary_e_summary_card__bt1sw .home-summary_e_total__hNQcA,
  // .section-title_e_title__EtJ6K,
  // .e-topper-card p,
  // .letter-board_e_leader_card__7jdw5 h5,
  // .footer_e_footer__TscIE .footer_e_footer_header__QlN-S,
  // .sideTab_e_tab__RsFLT,
  // .e-profile-container .e-tab-content .e-detail-sec P,
  // .tab_e_active_tab__R3Vxj,
  // .e-profile-container .e-game-dashboard .e-score,
  // .e-profile-container .e-game-dashboard .e-earnings-amount,
  // .e-profile-container .e-tab-content h5,
  // .summary_e_summary_card__YC1UZ p,
  // .accordion .accordion-button:not(.collapsed),
  // .accordion .accordion-button,
  // .e-select-wraper .css-qc6sy-singleValue,
  // .e-select-wraper .css-26l3qy-menu div,
  // .ant-picker,
  // .ant-picker-header,
  // .ant-picker-content th,
  // .ant-picker-cell-in-view,
  // .ant-picker-input > input,
  // .e-profile-container .e-bank-wraper p,
  // .refer_e_refer_card__0yVBP p,
  // .e-profile-container .e-refer-earn p,
  // .game-type_e_game_type_modal__DFkwT span,
  // .game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4 .game-detail_e_modal_header__CU6Qf h4,
  // .game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4 .game-detail_e_content_wrapper__fGJUm p,
  // .game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4 .game-detail_e_content_wrapper__fGJUm li,
  // .e-game-detail-wrapper h4,
  // .gameSummary_e_game_summary_card__phAbR .gameSummary_e_summary__M7XdU,
  // .gameSummary_e_game_summary_card__phAbR .gameSummary_e_name__lF3Kc,
  // .gameSummary_e_game_summary_card__phAbR .gameSummary_e_dull__Jxgc4,
  // .e-played-date p,
  // .e-played-date h6,
  // .e-milestone-pr p,
  // .e-milestone-pr p span,
  // .rdt_TableCell,
  // .fvAeSn a,
  // .gainers-losers_e_gainers_losers_card__x5FLy h5,
  // .gainers-losers_e_gainers_losers_card__x5FLy .gainers-losers_e_reward__3BvUZ,
  // .e-game-detail-wrapper .e-empty-table,
  // .game-result_e_game_result_modal__LtFbS .game-result_e_modal_content__p5leW .game-result_e_modal_header__rk\+Zi h4,
  // .game-result_e_game_result_modal__LtFbS .game-result_e_modal_content__p5leW h6,
  // .game-detail_e_check__lX\+UH,
  // .e-skill-race-container .e-preferred-wraper h5,
  // .e-skill-race-container .e-preferred-wraper label,
  // .e-skill-race-container .e-preferred-wraper p,
  // .e-skill-race-container .e-participants .e-skillrace-score span,
  // .e-skill-race-container h2,
  // .profile_e_menu_dropdown__jD0XD a,
  // .profile_e_menu_dropdown__jD0XD .profile_e_logout_div__UcMA- .profile_e_profile_bg__-LfL\+ h4,
  // .profile_e_menu_dropdown__jD0XD a,
  // .profile_e_menu_dropdown__jD0XD .profile_e_link__YBP1\+,
  // .indices_e_indices_modal__n3Tw7 .indices_e_modal_content__hi2E7 h5,
  // .checkBox_e_checkbox_container__3Ttss,
  // .search_e_search_box__Eq0CO,
  // .e-game-detail-wrapper h6,
  // .e-skill-race-container .e-watchlist-avg p,
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3 .iheader_e_green__bncrm span,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_modal_header__ORSCy h4,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo label,
  // .input_e_input_box__2pgsO,
  // .secondary_e_s_w_btn__73pfk,
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3 .iheader_e_red__jmb2A span,
  // .e-header-span span,
  // a .e-back,
  // .e-select-wraper .css-tlfecz-indicatorContainer,
  // .e-select-wraper .css-1gtu0rj-indicatorContainer:hover,
  // .e-skill-race-container .e-tutorial a,
  // .e-skillrace-table-body,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_low_balance_text__cQIn9,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_edit_watchlist_wraper__F5NVL,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_edit_watchlist_input__JEPwf,
  // .e-404-page h5,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_profile_card__90SQ\+ h6,
  // .PhoneInput input,
  // .e-wallet-card h5,
  // .e-wallet-card span,
  // .game-type_e_game_type_modal_home__DkGXj span,
  // .e-booking-wrapper label,
  // .nodata_e_nodata_wrapper__Gc6Z2 .nodata_e_title__RPeMq,
  // .e-uploader,
  // .e-profile-container .e-location,
  // .notificationList_e_notification_card__cBaHI .notificationList_e_content__GliX5,
  // .paymentModal_e_paymnet_modal__sd2bd .paymentModal_e_modal_header__KMZ7\+ h4,
  // .e-profile-container .e-side-wrap h5,
  // .scripmodal_e_scrip_modal__VeLba .scripmodal_e_modal_content__YR50T .scripmodal_e_modal_header__HDWpb h4,
  // .scripmodal_e_scrip_modal__VeLba .scripmodal_e_modal_content__YR50T h6,
  // .scripmodal_e_scrip_modal__VeLba .scripmodal_e_modal_content__YR50T label,
  // .e_table_input,
  // .game-result_e_game_result_modal__LtFbS .game-result_e_total_qty__F765x,
  // .e-select-wraper .css-1gtu0rj-indicatorContainer,
  // .e-pending-detail p,
  // .scripmodal_e_scrip_modal__VeLba .scripmodal_e_loader_content__pTi7s p,
  // .e-skill-race-container .e-activities-warpper p,
  // .customizeTable_e_customize_modal__LF3uv
  //   .customizeTable_e_modal_content__m0J2J
  //   .customizeTable_e_modal_header__obzBB
  //   h4,
  // .profile_e_menu_drop_down_menu__kN2RO .profile_e_profile_bg__-LfL\+ h4,
  // .profile_e_menu_drop_down_menu__kN2RO a,
  // .profile_e_menu_drop_down_menu__kN2RO .profile_e_link__YBP1\+,
  // .accordion-header:not(.faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_item__iAkKb),
  // .faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_title__KinlL,
  // .e-profile-container .e-wrap-mob h5,
  // .iheader_e_header_mobile_header__HLaHW .iheader_e_agent_wrap__bAOS3 span > span,
  // .scrip-card_e_card__ETPJd h5,
  // .scrip-card_e_card__ETPJd .scrip-card_e_data_mark__ipMa3,
  // .scrip-card_e_card__ETPJd .scrip-card_e_multiple_data_mark__clq8R,
  // .e-filter-card h6,
  // .e-scrip-voting-wrapper h5,
  // .e-filter label,
  // .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD .buyStock_e_toggle_label__HW8yV,
  // .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD p,
  // .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD h5,
  // .e-skill-race-container .radioButton_e_radiobutton__XQMbA label,
  // .radioButton_e_radiobutton__UO7wN label:before,
  // .e-total-added,
  // .e-skill-race-container .e-activities-warpper .e-icon,
  // .e-skill-race-container .e-remove-card span,
  // .e-profile-container .e-bank-wraper .e-more-dropdown div,
  // .preference_e_preference_modal__w-s5j .preference_e_modal_header__0yMg5 h4,
  // .radioButton_e_radiobutton__XQMbA label {
  //   color: $secondary_color !important;
  // }
  // .buyStock_e_buy_stock_modal__f96PT .buyStock_e_modal_content__bMlrD .buyStock_e_toggle_label_false__1tepK {
  //   color: $secondary_color !important;
  //   opacity: 0.6;
  // }

  // .iheader_e_header__bjleM .iheader_e_header_ul__5oTNQ .iheader_e_ac_balance__vxefc,
  // .game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4 .game-detail_e_content_wrapper__fGJUm,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo .kyc_e_input_bg__sA26H,
  // .scrip-card_e_card__ETPJd,
  // .e-filter-card,
  // .e-scrip-voting-wrapper,
  // .search_e_search_box__Eq0CO,
  // .e-skill-race-container .e-remove-card ,
  // .e-profile-container .e-bank-wraper .e-more-dropdown,
  // .preference_e_preference_modal__w-s5j .preference_e_bank_card__OcCTs {
  //   box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  // }

  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3,
  // .homeEarnings_e_card_wrapper__\+XYh0,
  // .home-summary_e_summary_card__bt1sw,
  // .e-topper-card,
  // .letter-board_e_leader_card__7jdw5,
  // .e-profile-container .e-side-wrap,
  // .e-profile-container .e-tab-content,
  // .summary_e_summary_card__YC1UZ,
  // .accordion .accordion-item:not(.faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_item__iAkKb),
  // .e-reports-filter-wrapper .e-select-wraper .css-1s2u09g-control,
  // .refer_e_refer_card__0yVBP,
  // .e-span,
  // .e-skill-race-container .search_e_search_wrapper__8i3JA,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_edit_watchlist_wraper__F5NVL,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT .edit_e_edit_watchlist_input__JEPwf,
  // .e-404-page,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_profile_card__90SQ\+,
  // .input_e_input_box__2pgsO,
  // .PhoneInput,
  // .e-wallet-card,
  // .game-type_e_game_type_modal_home__DkGXj,
  // .notificationList_e_notification_card__cBaHI,
  // .e-activity-layout,
  // .e-mobile-hand-burger-menu,
  // .iheader_e_header_mobile_header__HLaHW,
  // .e-profile-container .e-wrap-mob,
  // .iheader_e_header_mobile_header__HLaHW .iheader_e_agent_wrap__bAOS3 {
  //   box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
  // }

  // .card.e-topper-card {
  //   background-image: none;
  // }

  // .footer_e_footer__TscIE,
  // .accordion-header:not(.faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_item__iAkKb),
  // .accordion .accordion-button:not(.collapsed),
  // .accordion .accordion-button,
  // .ant-picker-panel-container,
  // .game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4.modal-content,
  // .e-profile-container .e-game-dashboard .e-milestone-content .e-milestone-wraper,
  // .game-result_e_game_result_modal__LtFbS .game-result_e_modal_content__p5leW.modal-content,
  // .indices_e_indices_modal__n3Tw7 .indices_e_modal_content__hi2E7.modal-content,
  // .search_e_search_wrapper__8i3JA .search_e_selected_item__AhA9U,
  // .kyc_e_kyc_modal__W\+k9T .kyc_e_modal_content__yA\+bo.modal-content,
  // .edit_e_edit_modal__5yfnB .edit_e_modal_content__SM\+lT.modal-content,
  // .modal-content,
  // .e-scrip-fixed .e-submit-scrip-sec,
  // .e-skill-race-container .e-activities-warpper,
  // .e-profile-container .e-wrap-mob,
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3 button,
  // .iheader_e_header_mobile_header__HLaHW .iheader_e_agent_wrap__bAOS3 button
  //  {
  //   background-color: $footer_bg_light !important;
  // }

  // .footer_e_footer__TscIE .footer_e_footer_link__dV1qa a,
  // .tab_e_tab__AKX3n {
  //   color: $placeholder;
  // }
  // .e-select-wraper .css-4ljt47-MenuList:hover,
  // .game-type_e_game_type_modal__DFkwT span:hover {
  //   color: $primary_color !important;
  // }

  // .e-red-bg {
  //   background-color: #5a1b1e !important;
  // }
  // .e-green-bg {
  //   background-color: #1f3b22 !important;
  // }
  // .rdt_TableRow.e-red-bg .rdt_TableCell,
  // .rdt_TableRow.e-green-bg .rdt_TableCell,
  // .e-span-disable {
  //   color: $white !important;
  // }
  // .checkBox_e_checkbox_container__3Ttss .checkBox_checkmark__JST4W {
  //   border: 1.5px solid $secondary_color;
  // }
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3 button {
  //   background: transparent;
  // }
  // .e-skill-race-container .e-tutorial a {
  //   border-bottom: 1.5px solid $secondary_color;
  // }
  // .PhoneInput {
  //   border: 2px solid transparent !important;
  // }
  // .game-type_e_game_type_modal_home__DkGXj .game-type_e_active__dCCe0,
  // .e-skill-race-container .e-watchlist-avg p.e-text-btn,
  // .profile_e_menu_dropdown__jD0XD a:hover,
  // .profile_e_menu_dropdown__jD0XD .profile_e_link__YBP1\+:hover,
  // .e-select-wraper .css-26l3qy-menu div:hover {
  //   color: #9c1fe9 !important;
  // }
  // .notificationList_e_notification_card__cBaHI .notificationList_e_notification_row__Xahot:hover {
  //   background-color: #eae9ef;
  // }
  // ::-webkit-scrollbar {
  //   border-radius: 8px;
  //   height: 4px;
  //   width: 4px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: $secondary_color;
  //   border-radius: 8px;
  // }
  // ::-webkit-scrollbar-track {
  //   border-radius: 8px;
  //   background: $footer_bg_light;
  // }
  // #droppable {
  //   background: whitesmoke !important;
  // }
  // .faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_button__3mORC {
  //   background-color: transparent;
  // }
  // .faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_item__iAkKb {
  //   background-color: transparent !important;
  // }
  // .faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_wrapper__UYJQC {
  //   border: 1px solid transparent;
  //   background: $footer_bg_light;
  //   box-shadow: 0px 2px 24px rgb(0 0 0 / 16%);
  // }
  // .faqAccordion_e_faq_accordion__iZIaG
  //   .faqAccordion_e_accordion_wrapper__UYJQC
  //   .faqAccordion_e_accordion_content__jKPXE,
  // .e-profile-container .e-wrap-mob span {
  //   color: $secondary_dark !important;
  // }
  // .faqAccordion_e_faq_accordion__iZIaG .faqAccordion_e_accordion_button__3mORC .faqAccordion_e_accordion_icon__iBaHD {
  //   background-image: url("../assets/images/other/down-dark.svg");
  //   background-size: 20px;
  // }
  // .reportAccordion_e_accordion_up__rFjBA {
  //   background-image: url("../assets/images/other/down-dark.svg");
  //   background-size: 28px;
  // }
  // .search_e_search_box__Eq0CO {
  //   border: 2px solid transparent !important;
  // }
  // .iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3 {
  //   color: #7d7688 !important;
  // }
  // .e-select-gender .css-1pahdxg-control:hover {
  //   background: white !important;
  // }
  // .e-reports-filter-wrapper .css-1pahdxg-control {
  //   box-shadow: 0px 2px 24px rgb(0 0 0 / 16%) !important;
  //   background-color: white !important;
  // }
  // .ant-picker {
  //   border: 1px solid rgba(0, 0, 0, 0.125);
  // }
}
