/*
 *   File : kyc.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : KYC Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

@import "./../config.scss";

.e_edit_modal {
	.e_modal_dialog {
		//  max-width: 960px!important;
	}

	.e_modal_dialog_popup {
		//  max-width: 960px!important;
		max-width: 540px;
	}
	.e_modal_dialog_popup_image {
		//  max-width: 960px!important;
		max-width: 435px;
		height: 575px;
	}

	.e_modal_content {
		padding: 36px 14px 30px 14px !important;
		border-radius: 32px !important;

		.e_modal_header {
			border-bottom: none !important;
			padding: 0 1rem !important;

			h4 {
				color: $white;
				font-size: 24px;
				font-family: $font-secondary;
				font-weight: 600 !important;
			}
		}

		.e_input_bg {
			background: #0d041d;
		}

		label {
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			color: #ffffff;
			font-family: $font-primary;
		}

		.e_edit_input {
			border: 1px solid $white;
		}

		.e_edit_input:focus {
			border: 1px solid $primary_color !important;
		}

		.e_cancel {
			padding: 8px 26px !important;
		}

		.e_edit_watchlist_wraper {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_edit_watchlist_input {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_icon {
			width: 16px;
			height: 16px;
			transition: ease 0.3s;

			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
		.e_img_low_balance {
			width: 100%;
			height: 200px;
			object-fit: cover;
		}
		.e_low_balance_text {
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			line-height: 150%;
			text-align: center;
			color: rgba(255, 255, 255, 0.8);
		}
	}
	.e_popup_modal_content {
		padding: 32px !important;
		border-radius: 8px;
		background: #130727;
		border: 1px solid rgba(156, 31, 233, 0.4);

		.e_popup_header {
			border-bottom: none !important;
			padding: 0 0rem !important;
			.e_btn_close {
				background-size: 22px;
				position: absolute;
				right: 24px;
				top: 8px;
			}
			.e_btn_close_dark {
				// background-size: 22px;
				position: absolute;
				right: 24px;
				top: 8px;
			}

			h4 {
				color: $white;
				font-size: 16px;
				font-family: $font-primary;
				font-weight: 700 !important;
				line-height: 20px;
				text-align: center;
			}
		}

		.e_input_bg {
			background: #0d041d;
		}

		label {
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			color: #ffffff;
			font-family: $font-primary;
		}

		.e_edit_input {
			border: 1px solid $white !important;
		}

		.e_edit_input:focus {
			border: 1px solid $primary_color !important;
		}

		.e_cancel {
			padding: 8px 26px !important;
		}

		.e_edit_watchlist_wraper {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_edit_watchlist_input {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_icon {
			width: 16px;
			height: 16px;
			transition: ease 0.3s;

			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
		.e_popup_image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			margin-top: 20px;
		}
		.e_description {
			font-family: $font-primary;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			color: rgba(255, 255, 255, 0.9);
			padding: 16px 0;
		}
		.e_continue {
			background: #4a06b8;
			box-shadow: 0px 1px 2px rgba(38, 110, 255, 0.05);
			border-radius: 8px;
			width: 100%;
			padding: 16px;
			&::before {
				background: none !important;
			}
		}
	}

	.e_popup_modal_content_image {
		border-radius: 4px;
		padding: 0px;

		.e_image {
			max-width: 435px;
			height: 575px;
		}
		.e_btn_close {
			background-size: 24px;
			position: absolute;
			right: -12px;
			top: -12px;
			background-image: url("../../assets/images/modal/plus-circle.svg");
		}
	}
	.e_nav {
		color: $white;
	}
}

.e_edit_modal_popup {
	background: rgba(0, 0, 0, 0.9);
	.e_modal_dialog {
		//  max-width: 960px!important;
	}

	.e_modal_dialog_popup {
		//  max-width: 960px!important;
		max-width: 540px;
	}
	.e_modal_dialog_popup_image {
		//  max-width: 960px!important;
		max-width: 435px;
		height: 575px;
	}

	.e_modal_content {
		padding: 36px 14px 30px 14px !important;
		border-radius: 32px !important;

		.e_modal_header {
			border-bottom: none !important;
			padding: 0 1rem !important;

			h4 {
				color: $white;
				font-size: 24px;
				font-family: $font-secondary;
				font-weight: 600 !important;
			}
		}

		.e_input_bg {
			background: #0d041d;
		}

		label {
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			color: #ffffff;
			font-family: $font-primary;
		}

		.e_edit_input {
			border: 1px solid $white !important;
		}

		.e_edit_input:focus {
			border: 1px solid $primary_color !important;
		}

		.e_cancel {
			padding: 8px 26px !important;
		}

		.e_edit_watchlist_wraper {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_edit_watchlist_input {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_icon {
			width: 16px;
			height: 16px;
			transition: ease 0.3s;

			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
		.e_img_low_balance {
			width: 100%;
			height: 200px;
			object-fit: cover;
		}
		.e_low_balance_text {
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			line-height: 150%;
			text-align: center;
			color: rgba(255, 255, 255, 0.8);
		}
	}
	.e_popup_modal_content {
		padding: 32px !important;
		border-radius: 8px;
		background: #130727;
		border: 1px solid rgba(156, 31, 233, 0.4);

		.e_popup_header {
			border-bottom: none !important;
			padding: 0 0rem !important;
			.e_btn_close {
				background-size: 22px;
				position: absolute;
				right: 24px;
				top: 8px;
			}
			.e_btn_close_dark {
				// background-size: 22px;
				position: absolute;
				right: 24px;
				top: 8px;
			}

			h4 {
				color: $white;
				font-size: 16px;
				font-family: $font-primary;
				font-weight: 700 !important;
				line-height: 20px;
				text-align: center;
			}
		}

		.e_input_bg {
			background: #0d041d;
		}

		label {
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			color: #ffffff;
			font-family: $font-primary;
		}

		.e_edit_input {
			border: 1px solid $white !important;
		}

		.e_edit_input:focus {
			border: 1px solid $primary_color !important;
		}

		.e_cancel {
			padding: 8px 26px !important;
		}

		.e_edit_watchlist_wraper {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_edit_watchlist_input {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_icon {
			width: 16px;
			height: 16px;
			transition: ease 0.3s;

			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
		.e_popup_image {
			width: 100%;
			height: 100%;
			object-fit: contain;
			margin-top: 20px;
		}
		.e_description {
			font-family: $font-primary;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			color: rgba(255, 255, 255, 0.9);
			padding: 16px 0;
		}
		.e_continue {
			background: #4a06b8;
			box-shadow: 0px 1px 2px rgba(38, 110, 255, 0.05);
			border-radius: 8px;
			width: 100%;
			padding: 16px;
			&::before {
				background: none !important;
			}
		}
	}

	.e_popup_modal_content_image {
		border-radius: 4px;
		padding: 0px;

		.e_image {
			max-width: 435px;
			height: 575px;
		}
		.e_btn_close {
			background-size: 24px;
			position: absolute;
			right: -12px;
			top: -12px;
			background-image: url("../../assets/images/modal/plus-circle.svg");
		}
	}
	.e_nav {
		color: $white;
	}
}

.e_cancel_modal {
	.e_modal_dialog {
		//  max-width: 960px!important;
	}

	.e_modal_content {
		padding: 36px 14px 30px 14px !important;
		border-radius: 32px !important;

		.e_modal_header {
			border-bottom: none !important;
			padding: 0 1rem !important;

			h4 {
				color: $white;
				font-size: 18px;
				font-weight: 500 !important;
				line-height: 28px;
			}
		}

		.e_input_bg {
			background: #0d041d;
		}

		.e_cancel {
			padding: 0px !important;
			border-bottom: 1px solid #7d7688 !important;
			color: #7d7688;
			border: none;
			border-radius: 0%;
			padding-bottom: 2px;
		}
		.e_sub_tile {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: $white;
		}
		label {
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			color: #ffffff;
			font-family: $font-primary;
		}

		.e_edit_input {
			border: 1px solid $white !important;
		}

		.e_edit_input:focus {
			border: 1px solid $primary_color !important;
		}

		.e_edit_watchlist_wraper {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_edit_watchlist_input {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_icon {
			width: 16px;
			height: 16px;
			transition: ease 0.3s;

			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
		.e_img_low_balance {
			width: 100%;
			height: 200px;
			object-fit: cover;
		}
		.e_low_balance_text {
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			line-height: 150%;
			text-align: center;
			color: rgba(255, 255, 255, 0.8);
		}
	}
	.e_nav {
		color: $white;
	}
}

.e_mpin_modal {
	.e_modal_dialog {
		max-width: 420px !important;
	}

	.e_modal_content {
		padding: 16px;
		border-radius: 16px !important;

		.e_modal_header {
			border-bottom: none !important;
			padding: 0 1rem !important;

			h4 {
				color: $white;
				font-size: 24px;
				font-family: $font-primary;
				font-weight: 600 !important;
			}
			h5 {
				color: $white;
				font-size: 16px;
				font-family: $font-primary;
				font-weight: 600 !important;
				line-height: 24px;
			}
		}

		.e_password {
			position: absolute;
			top: 55px;
			right: 30px;
			cursor: pointer;
		}
		.e_setpin_title {
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: rgba(255, 255, 255, 0.8);
			text-align: left;
		}
		.e_input_bg {
			background: #0d041d;
		}

		label {
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			color: #ffffff;
			font-family: $font-primary;
		}

		.e_edit_input {
			border: 1px solid $white !important;
		}

		.e_edit_input:focus {
			border: 1px solid $primary_color !important;
		}

		.e_cancel {
			padding: 8px 26px !important;
		}

		.e_edit_watchlist_wraper {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_edit_watchlist_input {
			background: #0d041d;
			border-radius: 8px;
			padding: 8px 16px;
			font-weight: 300;
			font-size: 14px;
			line-height: 170%;
			color: #ffffff;
		}

		.e_icon {
			width: 16px;
			height: 16px;
			transition: ease 0.3s;

			&:hover {
				cursor: pointer;
				opacity: 0.7;
			}
		}
		.e_img_low_balance {
			width: 100%;
			height: 200px;
			object-fit: cover;
		}
		.e_low_balance_text {
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			line-height: 150%;
			text-align: center;
			color: rgba(255, 255, 255, 0.8);
		}
		.e_label {
			border-color: $primary_color;
			color: $primary_color;
			font-weight: 600;
		}
	}

	.e_nav {
		color: $white;
	}
}
