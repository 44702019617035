/*
 *   File : reportAccordion.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 27-01-2022
 */

@import "./../config.scss";

.accordion {
  .accordion-item {
    background-color: #130727 !important;
    border-radius: 8px;
  }
  .accordion-button:not(.collapsed) {
    background-color: #130727 !important;
    border-radius: 8px;
    box-shadow: none;
  }

  .accordion-button:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
  .accordion-button:not(.collapsed)::after {
    display: none;
  }
  .accordion-button::after {
    display: none;
  }
  p {
    color: $white;
    @extend %font12;
  }
  .e-date-picker {
    padding: 16px 16px !important;
  }
  .e-report-download-btn {
    padding: 12.5px 0 !important;
  }
}
.e_accordion_up {
  background-image: url(../../assets/images/dataFormatting/downarrow.svg);
  background-size: 28px;
  background-position-y: -5px !important;
  background-repeat: no-repeat;
  padding-right: 22px;
}
.e_accordion_down {
  background-image: url(../../assets/images/dataFormatting/uparrow.svg);
  background-size: 28px;
  background-position-y: -5px !important;
  background-repeat: no-repeat;
  padding-right: 22px;
}
.accordion-header {
  @extend %font16;
  line-height: 20px;
  color: $white;
  background-color: #130727 !important;
  border-radius: 8px;
  padding: 22px 16px;
  z-index: 1;
  cursor: pointer;
}
.ant-picker-range .ant-picker-clear {
  right: 8px !important;
}
