/*
 *   File : secondary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Secondary Button Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 14-01-2022
 */

@import "./../config.scss";

.e_s_btn {
  outline: none;
  background: none;
  font-weight: 600;
  @extend %font16;
  line-height: 150%;
  border-radius: 8px;
  transition: 0.4s ease-in-out;
  
}
.e_s_p_btn{ 
  padding: 8px 16px;
  color: $primary_color; 
  border: 1px solid $primary_color;  
  &:hover {
    border: 1px solid $primary_color;
    color: $white;
    background:$primary_gradient;
  //  box-shadow: 4px 4px 24px rgba(154, 30, 232, 0.48);
}
}
.e_s_w_btn {
  padding: 16px 48px; 
  color: $white;
  border: 1px solid $placeholder;
  &:hover {
    border: 1px solid $primary_color;
    color: $primary_color;
  }
}
.e_s_disabled_btn{
  opacity: 0.5!important;
  pointer-events: none;
}
  
.e_loader{
  width: 80%;
  border-radius: 8px;
  padding: 5px 0px;
  border: 1px solid $primary_color;  
    .e_loader_img{
      width: 30px;
      // height: 50px;
    }
}