/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.e_tab {
  @extend %font16;
  font-weight: 400;
  line-height: 120%;
  color: $white;
  // transition: ease 0.7s;
  position: relative;
  //  background:  $secondary_color!important;
  padding: 20px 24px;
  border: 1px solid transparent;
  //  width: 100%;
  //  background-image: url('../../assets/images/dataFormatting/arrow.svg');
  //  background-repeat: no-repeat;
  //  background-position: 95% center;
  //  @extend %font14;
  &:hover {
    cursor: pointer;
    background: $primary_gradient !important;
    color: $white;
    // opacity: 0.8;
    //  transition: ease 0.7s;
    padding: 20px 24px;
    //  width: 100%;
    //  background-image: url('../../assets/images/dataFormatting/arrow.svg');
    //  background-repeat: no-repeat;
    //  background-position: 95% center;
  }
}
.e_rounded {
  border-radius: 0px 0px 16px 16px;
}
.e_active_tab {
  @extend .e_tab;
  //  transition: ease 0.3s;
  opacity: 1;
  background: $primary_gradient !important;
  color: $white;
  @extend %font14;
  padding: 20px 24px;
  //  width: 100%;
  //  background-image: url('../../assets/images/dataFormatting/arrow.svg');
  //  background-repeat: no-repeat;
  //  background-position: 95% center;
}
.e_active_tab_rounded {
  @extend .e_active_tab;
  border-radius: 0px 0px 16px 16px;
}
