/*
 *   File : select.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : SelectBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 27-01-2022
 */

@import "./../config.scss";

.e-select-wraper {
  .css-1s2u09g-control {
    border: 1px solid $primary_color !important;
    color: $white !important;
    padding: 0px 0px 0px 8px !important;
    background: #0d041d !important;
    border-radius: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  .css-14el2xx-placeholder {
    font-weight: 400 !important;
    @extend %font14;
    color: $white !important;
  }

  .css-qc6sy-singleValue {
    font-weight: 400 !important;
    @extend %font14;
    color: $white;
  }
  .css-1pahdxg-control {
    box-shadow: none !important;
    border: 1px solid $primary_color !important;
    border-radius: 24px !important;
    padding: 0px 0px 0px 8px !important;
    background: #0d041d !important;
    &:hover {
      box-shadow: none !important;
      border: 1px solid $primary_color !important;
      border-radius: 24px !important;
      background: #0d041d !important;
    }
  }
  .css-319lph-ValueContainer {
    // padding: 17px 16px !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
  .css-tj5bde-Svg,
  .css-8mmkcg {
    background-image: url(../../assets/images/dropdown/arrow.svg);
    // transform: rotate(180deg)!important;
    background-repeat: no-repeat;
    background-position: 0px;
  }
  .css-tlfecz-indicatorContainer {
    color: transparent !important;
    // transform: rotate(180deg)!important;
  }
  .css-1gtu0rj-indicatorContainer {
    color: transparent !important;
    &:hover {
      color: transparent !important;
    }
  }
  // .ccs-2613qy-menu {
  //   color: $black!important;
  //   background-color: none!important;
  // }
  .css-26l3qy-menu,
  .css-26l3qy-menu div,
  .css-26l3qy-menu span {
    font-size: 13px !important;
    // position: relative;
    z-index: 1;
    background: #0c031b !important;
    border-radius: 24px;
  }
  .css-9gakcf-option,
  .css-yt9ioa-option,
  .css-1n7v3ny-option {
    background: #0c031b !important;
    font-weight: 400;
    @extend %font14;
    line-height: 24px;
    color: $white !important;
    &:hover {
      cursor: pointer;
      background: $primary_color !important;
      border-radius: 0;
    }
  }

  .css-4ljt47-MenuList {
    color: $white !important;
    font-weight: 400;
    @extend %font14;
    line-height: 24px;
  }
  /* Scroll Bar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary_color;
  }
  .css-1s2u09g-control {
    min-height: 20px !important;
  }
  //  ::-webkit-scrollbar-thumb:hover {
  //    background: #0b1fca;
  //  }
}
.e-m2m-slot {
  .css-26l3qy-menu {
    width: 170px;
  }
}
.e-wallet-slot {
  .css-26l3qy-menu {
    width: 250px;
  }
}
.e-milestone-wraper {
  .css-26l3qy-menu,
  .css-26l3qy-menu div,
  .css-26l3qy-menu span {
    background: #1f1136 !important;
  }
  .css-9gakcf-option,
  .css-yt9ioa-option,
  .css-1n7v3ny-option {
    background: #1f1136 !important;
  }
}
