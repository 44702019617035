/*-- Import supporting css*/
@import "./config.scss";
@import "./components/datepicker.module.scss";
@import "./components/fileUpload.module.scss";
@import "./components/phoneInput.module.scss";
@import "./components/reportAccordion.module.scss";
@import "./components/select.module.scss";
@import "./components/dataTable.module.scss";
@import "./components/candle.module.scss";
@import "./components/skill-race-table.scss";
@import "./components/game-result.module.scss";
@import "./light-theme.scss";

/*---------- Body ---------- */
body {
	font-family: $font-primary;
	background: $secondary_dark;
	-webkit-font-smoothing: antialiased !important;
	-webkit-appearance: none !important;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		display: none !important;
	}

	.e-body {
		background: $secondary_dark;
	}
	// overflow: auto;
}

* {
	scrollbar-width: none;
}

form[type="reset"],
form[type="submit"],
form[type="button"] {
	-webkit-appearance: none !important;
}

button[type="reset"],
button[type="submit"] {
	-webkit-appearance: none !important;
}

form[type="reset"],
form[type="submit"],
form[type="button"] {
	-webkit-appearance: none !important;
}

button[type="reset"],
button[type="submit"] {
	-webkit-appearance: none !important;
}
.e-mode-toggle {
	top: -3px;
	margin: 0 10px 0 20px;
}

//home
.e-topper-card {
	background-image: url("../assets/images/card/topEarnerBg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 26px;
	border: 0;

	label {
		font-family: "Sora";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 120%;
		color: #7d7688;
	}

	p {
		font-family: "Sora";
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 120%;
		color: #ffffff;
	}

	.e-placeholder {
		width: 60px;
		height: 60px;
		color: #fff;
		font-weight: 600;
		font-size: 24px;
		background: $primary_gradient;
		border-radius: 100px;
		padding-top: 13px;
	}

	.e-topper-trophy {
		position: absolute;
		height: 115px;
		right: 45px;
		top: -65px;
	}
	.e-topper-card-img-border {
		width: 60px;
		height: 60px;
		background: $primary_gradient;
		border-radius: 100px;
		text-align: center;
		.e-topper-card-img {
			margin: auto;
			width: 50px;
			height: 50px;
			border-radius: 100px;
			margin-top: 4.6px;
			object-fit: cover;
		}
	}
}
.e-leaders_name {
	font-size: 14px !important;
	font-size: 14px !important;
	width: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/*File uploader*/
.e-uploader {
	// background: rgba(156, 31, 233);
	background: #451166;
	border: 2px dashed $primary_color;
	outline: none;
	width: 100%;
	font-weight: 700;
	color: $white;
	padding: 18px 16px;
	border-radius: 8px;
	@extend %font14;
	cursor: pointer;

	&:hover {
		border: 2px dashed $primary_color;
	}
}

.e-upload-clear {
	right: 20px;
	position: absolute;
	width: 20px;
	height: 20px;
	object-fit: cover;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

/*-----Auth-------*/
.e-body {
	padding-top: 6.5rem;
}

.e-login-banner {
	max-width: 320px;
}

.e-login-l-wrapper {
	background: $primary_gradient;
	width: 40%;
	padding-bottom: 45px;
	min-height: 100vh;

	h3 {
		@extend %h3;
		font-family: $font-secondary;
	}

	p {
		@extend %p;
		line-height: 170%;
		opacity: 0.67;
		font-family: $font-primary;
	}
}
.e-mpin-password {
	position: absolute;
	top: 60px;
	right: 30px;
	cursor: pointer;
}
.e-text {
	color: $white;
}
.e-resend-otp {
	p {
		@extend %font14;
	}
}
.e-login-r-wrapper {
	.e-login-nav {
		p {
			opacity: 0.67;
			color: $white;
			font-weight: 300;
			@extend %font14;
		}
	}

	background: $secondary_dark;
	background-image: url("../assets/images/auth/bg.png");
	background-position: 100% 105%;
	background-repeat: no-repeat;
	width: 60%;

	.e-login-wraper {
		cursor: default !important;
		padding-top: 50px;

		h4 {
			@extend %h4;
		}
	}
}

.e-login-logo {
	transition: ease-in-out 0.5s;
	width: 210px;
	&:hover {
		transform: scale((0.8));
	}
}

.e-login-switch {
	background: #1f1136;
	color: $primary_color;
	font-size: 13px;
	display: inline-block;
	font-weight: 700;
	width: 98%;
	height: 31px;
	border-radius: 4px;
	margin-left: 0.6px;
	margin-top: 1.5px;
	cursor: pointer;
	position: relative;
	padding-top: 5px;
}

.e-left-padding {
	padding-left: 0.5px;
}

.e-login-active {
	color: $white;
	padding-top: 5px;
}

.e-gradient-border {
	width: 90px;
	border-radius: 4px;
	height: 33px;
	background: linear-gradient(90deg, #9c1fe9 0%, #4a06b8 100%);
	display: inline-block;
}

/*---Sign up-------*/
.e-login-auth-nav {
	p {
		opacity: 0.87;
		color: $placeholder;
		font-weight: 400;
		@extend %font12;
	}

	.e-auth-links {
		@extend %font12;
		color: $primary_color;
		font-weight: 600;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 0;
			height: 1.5px;
			background-color: $primary_color;
			transition: all 0.5s;
			bottom: -2px;
			left: 0;
		}
		&:hover {
			&::after {
				width: 100%;
			}
		}
	}
}

/*------Kyc modal------*/
.e-select-gender {
	.css-1s2u09g-control {
		border: none !important;
		color: $white !important;
		padding: 14px 8px !important;
		background: #0d041d !important;
		border-radius: 8px;
		min-height: 20px;

		&:hover {
			cursor: pointer;
		}
	}

	.css-14el2xx-placeholder {
		font-weight: 400;
		line-height: 18px;
		color: $placeholder;
		@extend %font14;
	}

	.css-qc6sy-singleValue {
		font-weight: 400;
		line-height: 18px;
		color: $white;
		@extend %font14;
	}

	.css-1pahdxg-control {
		box-shadow: none !important;
		border: 2px solid $primary_color !important;
		border-radius: 8px !important;
		padding: 14px 8px !important;
		background: #0d041d !important;
		min-height: 20px;

		&:hover {
			box-shadow: none !important;
			border: 2px solid $primary_color !important;
			border-radius: 8px !important;
			background: #0d041d !important;
		}
	}
}

/*------Profile-------*/
.e-profile-container {
	padding-bottom: 200px;
	.e-user-image-wrapper {
		width: 80px;
		height: 80px;
		border-radius: 100px;
		background: $primary_gradient;
		position: relative;
		margin: auto;
		.e-user-image {
			width: 70px;
			height: 70px;
			border-radius: 100px;
			margin-top: 5px;
			object-fit: cover;
		}
		.e-edit-img {
			position: absolute;
			right: 5px;
			bottom: 0;
			cursor: pointer;
		}
		.e-profile-upload-loader {
			position: absolute;
			width: 80px;
			height: 80px;
			border-radius: 100px;
			background-color: transparent;
			top: 0;
			left: 0;
			div {
				position: relative;
				&::after {
					content: "";
					position: absolute;
					background-color: black;
					opacity: 0.5;
					width: 70px;
					height: 70px;
					top: 5px;
					left: 5px;
					border-radius: 100px;
				}
				img {
					margin-top: 14px;
					width: 50px;
					z-index: 20;
					height: 50px;
					position: relative;
				}
			}
		}
	}
	.e-customer-id {
		margin-top: 10px;
		color: #7c7587;
		font-size: 14px;
	}
	.e-wrap-mob {
		background: $secondary_color;
		border-radius: 24px;
		padding: 24px;

		h5 {
			color: $white;
			font-family: $font-secondary;
			font-weight: 700;
			@extend %font18;
		}

		span {
			font-weight: 400;
			@extend %font14;
			color: #219653;
		}
	}

	.e-side-wrap {
		background: $secondary_color;
		border-radius: 24px;
		height: 595px;

		h5 {
			color: $white;
			font-family: $font-secondary;
			font-weight: 700;
			@extend %font18;
		}

		span {
			font-weight: 400;
			@extend %font14;
			color: #219653;
		}
	}

	.e-tab-content {
		background: $secondary_color;
		border-radius: 16px;
		padding: 32px;

		.e-play-btn {
			width: 16px;
			height: 16px;
		}

		.e-how-it-works {
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			color: $white;
			text-decoration: underline;
		}

		.e-tab {
			font-weight: 600;
			@extend %font18;
		}

		.e-detail-sec {
			h6 {
				color: $white;
				font-weight: 600;
				@extend %font14;
				line-height: 120%;
				color: $placeholder;
			}

			P {
				font-weight: 400;
				@extend %font16;
				line-height: 120%;
				color: $white;
			}

			.e-auto-detect {
				color: $primary_color;
				font-weight: 600;
				cursor: pointer;
			}

			.e-profile-edit {
				cursor: pointer;

				&:hover {
					opacity: 0.7;
				}
			}
		}

		h5 {
			@extend %h5;
			font-weight: 600;
			font-family: $font-secondary;
		}

		.e-kyc-wrapper {
			background: $modal_bg;
			border-radius: 16px;
			padding: 16px;

			p {
				color: $white;
				@extend %font14;
			}
		}
	}
	.e-location {
		color: $white !important;
		font-weight: 400;
		cursor: default;
	}

	.e-game-dashboard {
		.e-earnings {
			@extend %font16;
			font-weight: 400;
		}
		.e-earnings-amount {
			font-size: 22px !important;
			font-weight: 600 !important;
			color: $white !important;
		}

		h6 {
			color: $placeholder;
			@extend %font12;
			font-weight: 700;
		}

		.e-game-name {
			font-weight: 400;
			@extend %font14;
			line-height: 190%;
			color: $placeholder;
		}

		.e-score {
			color: $white;
			font-weight: 700;
		}

		.e-win {
			font-weight: 400;
		}

		.e-total-earnings {
			p {
				font-weight: 600;
				@extend %font24;
				color: $white;
			}

			span {
				@extend %font14;
			}
		}

		h5 {
			@extend %h5;
		}

		.e-played-history {
			h6 {
				font-weight: 600;
				@extend %font14;
				line-height: 120%;
				color: $placeholder;
			}
		}

		.e-border-bottom {
			border-bottom: 1px solid #2b213a;
		}

		.e-played-date {
			p {
				font-weight: 500;
				@extend %font14;
				line-height: 140%;
				color: $white;
			}

			span {
				font-weight: 500;
				@extend %font12;
				line-height: 140%;
				color: $placeholder;
				margin-left: 10px;
			}

			h6 {
				font-weight: 500;
				@extend %font14;
				line-height: 140%;
				color: $white;
			}

			.e-result-btn {
				@extend %font16;
			}
		}

		.e-milestone-content {
			// &::after{
			// 	content: '';
			// 	background-color: #1f1136;
			// 	opacity: 0.95;
			// 	position: absolute;
			// 	width: 100%;
			// 	height: 100%;
			// 	top: 0;
			// 	left: 0;
			// }
			.e-delay-wrapper {
				z-index: 2;
				.e-content {
					position: absolute;
					top: 150px;
				}
				h6 {
					color: #ffffff;
					line-height: 150%;
					font-size: 24px;
					font-weight: 600;
				}
				p {
					color: rgba(255, 255, 255, 0.9);
					font-family: "Inter";
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 150%;
					z-index: 10;
				}
			}
			.e-tooltip-icon {
				&:hover {
					opacity: 0.7;
					cursor: pointer;
				}
			}

			.e-milestone-gif {
				width: 50px;
				height: 50px;
			}

			.e-milestone-pr {
				padding-right: 60px;
			}

			.e-milestone-ps {
				padding-left: 60px;
			}

			p {
				font-weight: 600;
				@extend %font14;
				line-height: 120%;
				color: $white;
			}

			span {
				color: $placeholder;
				@extend %font14;
			}

			.e-milestone-wraper {
				background: $modal_bg;
				border-radius: 16px;
				padding: 16px;

				h6 {
					color: $placeholder;
					font-size: 13px;
					font-weight: 700;
				}

				h5 {
					font-weight: 700;
					@extend %font16;
					line-height: 20px;
					color: $white;
				}

				p {
					font-weight: 400;
					@extend %font12;
				}

				span {
					font-weight: 700;
					color: $white;
				}

				.e-acheivements {
					font-weight: 400;
					@extend %font12;
					line-height: 16px;
					color: $placeholder;
				}
			}
		}
	}

	.e-bank-wraper {
		.e-wrapper {
			background: $secondary_dark;
			border-radius: 8px;
			padding: 18px;
		}

		.accordion-button {
			background-color: transparent !important;
		}
		.e-bank-name {
			color: $white;
		}
		h5 {
			@extend %h5;
		}

		.e-more-icon {
			cursor: pointer;
		}

		.e-more-dropdown {
			position: absolute;
			z-index: 3;
			right: 19px;
			top: 4px;
			width: 208px;
			height: max-content;
			background: $secondary_color;
			box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
			border-radius: 8px;
			padding: 16px 24px;

			div {
				font-weight: 500;
				@extend %font14;
				line-height: 24px;
				letter-spacing: -0.02em;
				color: $white;

				&:hover {
					cursor: pointer;
					opacity: 0.7;
				}
			}
		}

		h6 {
			font-weight: normal;
			@extend %font16;
			line-height: 20px;
			color: $placeholder;
		}

		p {
			font-weight: 600;
			@extend %font16;
			line-height: 24px;
			color: $white;
		}
	}

	.e-refer-earn {
		.e-referral-btn {
			padding: 20px 40px;
		}

		p {
			color: $white;
			font-weight: 400;
			@extend %font16;
		}

		img {
			margin-left: 30px;

			&:hover {
				transition: 0.4s ease-in;
				transform: scale(1.2);
			}
		}
	}

	.e-tab {
		font-weight: 600;
		@extend %font18;
	}

	.e-note {
		color: $placeholder;
		@extend %font14;
	}
	.e-bank-detail-add {
		height: 200px;
	}
	.e-add-bank-btn {
		width: 50% !important;
		height: 55px !important;
	}
}

/* wallet*/
.e-wallet-card {
	background-color: $secondary_color;
	border-radius: 24px;
	position: relative;
	padding-bottom: 85px;

	h5 {
		color: $white;
		font-feature-settings: "kern" off;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		font-family: sora;
	}

	h6 {
		color: $placeholder;
		font-feature-settings: "kern" off;
		font-weight: 600;
		font-size: 18px;
		line-height: 32px;
		font-family: sora;
	}

	.e-amount {
		position: relative;
		z-index: 2;
		@extend h5;
		font-family: inter;
		font-size: 20px !important;
	}

	.e-img {
		position: absolute;
		width: 228px;
		bottom: 0;
		right: 0;
		border-radius: 24px;
	}
}

.e-amount-card {
	background-color: $modal_bg;
	border-radius: 24px;

	.e-tab-name {
		font-size: 18px;
		font-weight: 500;
	}

	.e-add-money {
		width: 100%;
		height: 56px;
	}
}

.e-filter-card {
	background-color: $modal_bg;
	border-radius: 24px;
	padding: 32px !important;

	h6 {
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		font-family: sora;
		color: $white;
	}
}

.e-wallet-historty-time {
	font-weight: 500;
	font-size: 12px;
	line-height: 140%;
	color: $placeholder;
}

/*scrips*/
.e-back-btn {
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.01em;
	color: $white;
	text-decoration: none;
	transition: ease-in-out 0.5s;

	&:hover {
		text-decoration: none;
		color: $primary_color;
	}

	img {
		width: 12.8px;
		height: 12.8px;
	}
}

.e-scrip-tab {
	font-family: Sora;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 120%;
}

.e-more {
	color: $primary_color;
	font-weight: 600;
	cursor: pointer;
	padding-top: 12px;
}

.e-scrip-voting-list {
	// height: 300px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		border-radius: 10px;
		height: 10px;
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background: $primary_color;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
	}
}

.e-scrip-votin-logo {
	width: 41px;
	height: 41px;
	border-radius: 50%;
	object-fit: cover;
}

/* progress bar */
.CircularProgressbar {
	height: 74px !important;
	width: 74px !important;
	background-color: #1f1136;
	border-radius: 100px !important;
}

.CircularProgressbar .CircularProgressbar-path {
	// stroke: #2ed477 !important;
	stroke-width: 6px;
}

.CircularProgressbar .CircularProgressbar-trail {
	stroke: #ffffff !important;
	stroke-width: 5px;
}

.CircularProgressbar .CircularProgressbar-text {
	font-weight: 700;
	@extend %font16;
	line-height: 15px;
	fill: $white !important;
}

.e-scrip-list {
	input {
		padding: 8px 29px 8px 16px !important;
	}
}
/*Game detail*/
.e-game-detail-wrapper {
	h4 {
		color: $white;
		font-weight: 700;
		font-family: $font-secondary;
	}

	.e-customize {
		color: $primary_color;
		font-weight: 600;
		@extend %font14;
		background: none;
		border: none;
		outline: none;
		line-height: 150%;
		position: relative;
		transition: background-size 0.3s linear;
		text-decoration: none;
		background: linear-gradient(to right, #9c1fe9 0%, #9c1fe9 98%);
		background-size: 0px 2px;
		background-repeat: no-repeat;
		background-position: left 100%;
		padding: 0 0 7px 0;
		cursor: pointer;

		&:hover {
			background-size: 100% 2px;
		}
	}

	.e-evaluated-time {
		font-weight: 400;
		@extend %font16;
		line-height: 120%;
		color: $placeholder;
	}

	.e-refresh-btn {
		cursor: pointer;
	}

	.e-slot-wrapper {
		@extend %font14;
		line-height: 20px;
		color: $white;
		border: 1px solid $primary_color;
		border-radius: 24px;
		padding: 6px 12px;
		cursor: pointer;
		font-weight: 400;
		height: 34px;
	}

	.e-slot-wrap {
		@extend %font12;
		line-height: 20px;
		color: $white;
		border: 1px solid $primary_color;
		border-radius: 24px;
		padding: 8px 12px;
		cursor: pointer;
		font-weight: 400;
	}

	h6 {
		color: $primary_color;
		@extend %font16;
		font-weight: 600;
	}

	.e-cancel-btn {
		border: 1px solid #1f1136;
		padding: 8px 16px;

		&:hover {
			border: 1px solid $primary_color;
			color: $white;
			background: $primary_gradient;
		}
	}

	.e-empty-table {
		color: $white;
	}
}

.e-news-section {
	height: 138vh;
	overflow-y: scroll;
	cursor: pointer;
	overflow-x: hidden;
}

.e-table-rounded-border .sc-hHLeRK {
	max-height: 675px;
	overflow-y: scroll;
}
.e-scrip-code {
	border-bottom: solid 1px #9c1fe9;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
}

.e-view-table {
	.rdt_Table {
		overflow-y: scroll !important;
		&::-webkit-scrollbar {
			display: none;
		}
		scrollbar-width: none;
	}
}
.e-scrip-fixed {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 10;
	.e-submit-scrip-sec {
		background: $secondary_dark;
		border-radius: 24px 24px 0px 0px;
		padding: 12px 12px 12px 12px;
		border: solid 1px $primary_color;
	}
}

a {
	text-decoration: none;

	.e-back {
		color: $white;
		font-weight: 400;
		@extend %font12;
		line-height: 15px;
		letter-spacing: -0.01em;
	}
}
.e-back-arrow {
	width: 15px;
	height: auto;
}
.e-back {
	&:hover {
		cursor: pointer;
		color: $primary_color;

		.e-back-arrow {
			transform: translateX(-10%);
			transition: 0.4s ease-in-out;
		}
	}
}

/*Bookings*/

.e-booking-wrapper {
	padding-bottom: 150px;

	p {
		font-weight: 600;
		@extend %font14;
		line-height: 120%;
		color: $placeholder;
	}
}

/*Scrip voting*/
.e-scrip-voting-wrapper {
	background-color: $secondary_color;
	border-radius: 16px;
	padding: 34px 32px;

	h5 {
		@extend %h5;
		color: $white;
		font-weight: 600;
		font-family: $font-secondary;
	}

	span {
		font-weight: 500;
		@extend %font14;
		line-height: 140%;
		color: $placeholder;
	}
}

.e-disabled-span-input {
	opacity: 0.2;
	pointer-events: none;
}

.e-booking-confirm-check {
	span {
		top: 6px !important;
	}

	color: #a1a1a1 !important;
}

.e-shadow {
	z-index: 999 !important;
	opacity: 1 !important;
	height: 60px;
	border: 1px solid #1f1136;
	box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.5) !important;
}

/*Scrip detail*/
.e-scrip-detail {
	.e-tab {
		font-weight: 600;
		@extend %font18;
	}

	h5 {
		font-family: $font-secondary;
		font-weight: 800;
		@extend %font18;
		color: $white;
	}

	.e-cart-info {
		h6 {
			font-weight: 600;
			@extend %font14;
			line-height: 120%;
			color: $placeholder;
		}

		a {
			color: $primary_color;
			@extend %font14;

			&:hover {
				text-decoration: underline;
			}
		}

		.e-about {
			font-weight: 400;
			line-height: 140%;
		}
	}

	.e-status {
		font-weight: 600 !important;
	}

	p {
		font-weight: 500;
		@extend %font14;
		line-height: 140%;
		color: $white;
	}

	.e-performance-wrapper {
		background: $secondary_color;
		border-radius: 24px;
		padding: 24px;

		p {
			font-weight: 500;
			@extend %font24;
			line-height: 140%;
			color: $white;
		}

		span {
			font-weight: 600;
			@extend %font14;
			line-height: 120%;
			color: $placeholder;
		}
	}

	.e-chart-wrapper {
		background: $modal_bg;
		// transform: matrix(-1, 0, 0, 1, 0, 0);
		padding: 32px;

		h6 {
			font-family: $font-secondary;
			font-weight: 600;
			@extend %font16;
			line-height: 18px;
			color: $white;
		}
	}

	.e-span-wrap {
		cursor: pointer;

		.e-profile-active-btn {
			background: $primary_color;
			border-radius: 8px;
			color: $white;
			@extend %font12;
			padding: 8px;
			width: 100%;
			margin-right: 8px;
			font-weight: 400;
		}

		.e-profile-btn {
			background: $modal_bg;
			border-radius: 8px;
			color: rgb(255, 255, 255, 0.47);
			@extend %font12;
			padding: 8px;
			width: 100%;
			margin-right: 8px;
			font-weight: 400;
		}

		.e-filter-btn {
			background: $secondary_color;
			border-radius: 8px;
			color: rgb(255, 255, 255, 0.47);
			@extend %font12;
			padding: 8px;
			width: 100%;
			margin-right: 8px;
			font-weight: 400;
		}
	}
}

.iheader_e_header_ul__rQ0DU .e-responsive-nav1 {
	display: none;
}

.e-mobile-agent-board {
	display: none;
}

.e-dashboard-select {
	// position: absolute;
	// margin-top: -6% !important;
	// right: 54px;
	position: relative;
	display: flex;
	justify-content: flex-end;

	.e-dashbaord-dropdown {
		border: 1px solid #6d6d6d;
		box-sizing: border-box;
		border-radius: 4px;
		min-height: 28px;
		background-image: url("../assets/images/dropdown/arrow-down.svg");
		margin-right: 1.25rem;
		margin-bottom: 10px;
		background-repeat: no-repeat;
		background-position: 95% center;
		color: $white;
		display: flex;
		align-items: center;
		padding: 8px 50px 8px 10px;
		font-weight: 400;
		@extend %font12;
		min-width: 210px;
		cursor: pointer;
	}
	.css-1s2u09g-control,
	.css-1pahdxg-control {
		border: 1px solid #6d6d6d !important;
		box-sizing: border-box;
		border-radius: 4px !important;
		min-width: 130px;
		min-height: 36px !important;
		background-color: transparent !important;
		.css-qc6sy-singleValue,
		.css-6j8wv5-Input {
			font-weight: 400;
			@extend %font12;
			color: $white;
		}
		.css-tj5bde-Svg,
		.css-8mmkcg .css-tj5bde-Svg {
			width: 16px;
			height: 16px;
			background-image: url("../assets/images/dropdown/arrow-down.svg");
		}
	}
	.e-show-dropdown {
		position: absolute;
		background-color: #0d041d;
		box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
		padding: 14px;
		border-radius: 8px;
		top: 90%;
		z-index: 1;
		cursor: pointer;
		width: 130px;
		p {
			margin-bottom: 24px;
			color: $white;
		}
		.e-arrow-show {
			background-repeat: no-repeat;
			background-position: 95% center;
			background-image: url("../assets/images/dropdown/side-arrow.svg");
		}
	}

	.e-second-show-dropdown {
		position: absolute;
		background-color: #0d041d;
		box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
		padding: 14px;
		border-radius: 8px;
		top: 3%;
		z-index: 2;
		cursor: pointer;
		width: 156px;
		right: 140px;
		height: 200px;
		overflow-y: scroll;
	}

	// .css-1s2u09g-control,.css-1pahdxg-control{
	//   background-color: transparent!important;
	//   min-height: 20px!important;
	// }
	// .css-qc6sy-singleValue{
	//   color: $white;
}

.date-picker-wrapper {
	right: 50% !important;
}
.sc-kYWVYA,
.sc-dUWWNf,
.sc-iWajrY {
	color: $white;
}

.e-dashboard-range {
	position: relative;
	background-image: url("../assets/images/dropdown/side-arrow.svg") !important;
	background-repeat: no-repeat;
	background-position: 95% center;
	.ant-picker {
		border: none !important;
		padding: 10px 10px 0px 10px !important;
		background: transparent !important;
		input:placeholder-shown {
			display: none !important;
		}
	}
	.ant-picker-suffix {
		display: none !important;
	}
	.ant-picker-input > input {
		font-size: 0px !important;
		display: none !important	;
	}
	.e-ant-daterange-picker::before {
		content: "Date range";
		position: absolute;
		color: $white;
	}
}

.ant-picker-panel-container {
	background-color: #130727 !important;
}
.e-ant-daterange-picker {
	.ant-picker-range-separator {
		display: none !important;
	}
}
// Mobile hand burger menu
.e-mobile-hand-burger-menu {
	height: 100%;
	position: fixed;
	top: 0;
	width: 100%;
	background-color: #1f1136;
	z-index: 9999;
	right: -999vh !important;
	overflow-y: auto;
}

.e-show-menu {
	right: 0vh !important;
	transition: right 0.5s;
}

.e-menu-close {
	width: 55px;
	height: 30px;
	position: absolute;
	right: 10px;
	top: 28px;

	&:hover {
		cursor: pointer;
	}
}

.e-menu-overlay {
	opacity: 0.8;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: black;
	width: 100%;
	height: 100%;
	transition: all ease;
	z-index: 1030;
}

/*------------loader---*/
.e-btn-loader span {
	&:after {
		content: " .";
		font-family: "Epilogue";
		margin: 0;
		line-height: 100%;
		animation: dots 1s steps(1, end) infinite;
	}
}

.e-butn-loader {
	&:after {
		content: " .";
		font-family: "Epilogue";
		margin: 0;
		line-height: 100%;
		animation: dotss 1s steps(1, end) infinite;
	}
}

@keyframes dots {
	0%,
	12.5% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	50% {
		text-shadow: 0.5em 0;
	}

	75%,
	100% {
		text-shadow: 0.5em 0, 1em 0;
	}
}

@keyframes dotss {
	0%,
	12.5% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	50% {
		text-shadow: 0.5em 0;
	}

	75%,
	100% {
		text-shadow: 0.5em 0, 1em 0;
	}
}
.e-remove-card {
	width: 138px;
	background: #130727;
	color: $white;
	border-radius: 8px;
	position: absolute;
	right: 0;
	z-index: 1;
	padding: 15px;
	line-height: 25px;
	cursor: pointer;
	span {
		margin-bottom: 12px;
		@extend %font14;

		&:hover {
			opacity: 0.7;
		}
	}
}

.e-remove-card-m2e {
	top: 0px;
	left: 5px;
	padding: 8px;
	width: 138px;
	background: #130727;
	color: $white;
	border-radius: 8px;
	position: absolute;
	right: 0;
	z-index: 1;
	padding: 15px;
	line-height: 25px;
	cursor: pointer;
	span {
		margin-bottom: 12px;
		@extend %font14;

		&:hover {
			opacity: 0.7;
		}
	}
}
/*---------------Skill race detail---------*/
.e-skill-race-container {
	h2 {
		color: $white;
		@extend %font36;
		font-family: $font-secondary;
		font-weight: 800;
	}

	.e-tutorial {
		a {
			color: $white;
			position: absolute;
			// text-decoration: underline;
			font-weight: 500;
			@extend %font14;
			line-height: 16px;
			border-bottom: 1.5px solid $white;

			cursor: pointer;
		}
		.e-playBtn {
			width: 16px;
			height: 16px;
		}
	}

	.e-scrip-search {
		background-color: $secondary_color !important;
	}

	.search_e_search_wrapper__8i3JA {
		background-color: $secondary_color !important;
	}

	.e-participants {
		.e-skillrace-score {
			font-weight: 600;
			@extend %font12;
			line-height: 15px;
			color: $placeholder;
			margin-right: 15px;

			span {
				color: $white;
			}
		}
	}

	.e-end-time {
		background: $black;
		border-radius: 100px;
		padding: 10px 16px;
		position: inherit;
		bottom: 10px;
		width: max-content;
	}

	.e-border-bottom {
		border-bottom: 1px solid $secondary_color;
	}

	.tab_e_active_tab__R3Vxj {
		font-weight: 600;
		@extend %font18;
	}

	.tab_e_tab__AKX3n {
		font-weight: 600;
		@extend %font18;
	}

	.e-watchlist-avg {
		p {
			font-weight: 300;
			@extend %font14;
			line-height: 32px;
			color: $white;
			margin-right: 15px;

			span {
				font-weight: 600;
				@extend %font20;
			}
		}

		.e-text-btn {
			color: $primary_color;
			@extend %font16;
			font-weight: 600;
		}

		.e-text-btn-active {
			color: $white;
			@extend %font16;
			font-weight: 600;
			transition: ease 0.3s;
			padding-bottom: 2px;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $primary_color;
				bottom: 0px;
				left: 0;
			}
		}
	}
	.e-sell-price {
		// padding: 10px;
		// text-align: center;
	}
	.e-exit-game {
		color: #fa6363;
		margin-left: 15px;
		font-weight: 600;
	}
	.e-preferred-wraper {
		background: $secondary_color;
		border-radius: 24px;
		padding: 32px;
		height: 100%;
		h5 {
			@extend %h5;
		}

		.e-reward-time {
			font-weight: 400;
			font-size: 14px;
		}

		.e-span-reward {
			color: #d9a214;
		}

		label {
			font-weight: 400;
			@extend %font14;
			line-height: 120%;
			color: #7d7688;
			display: block;
		}

		p {
			font-weight: 600;
			@extend %font14;
			line-height: 140%;
			color: $white;
		}

		.e-bookings {
			color: $white;
			font-weight: 400;
		}
	}
	.e-remove-activity {
		cursor: pointer;
		transition: ease 0.5s;
		&:hover {
			transform: scale(0.8);
		}
	}
	.e-activities-warpper {
		background: #0d041d;
		border-radius: 16px;
		padding: 8px;

		label {
			font-weight: 600;
			@extend %font14;
			line-height: 120%;
			color: #7d7688;
			margin-top: -2px;
		}

		p {
			font-weight: 400;
			@extend %font14;
			line-height: 140%;
			color: $white;
		}

		.e-positive {
			color: #16bf64;
		}

		.e-icon {
			font-weight: 400;
			@extend %font14;
			line-height: 140%;
			color: $white;
		}

		.e-sl-input {
			border: 2px solid #7d7688;
			width: 85px;
			padding: 4px 8px !important;
			font-size: 14px;
			border-radius: 4px;
		}

		.primary_e_p_full_width_btn__dpLjR {
			padding: 8px 11px;
		}

		.primary_e_p_btn__\+SQh7 {
			padding: 6px 11px;
		}

		.primary_e_p_btn__\+SQh7 {
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			color: $white;
			text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		}

		.primary_e_p_btn__\+SQh7::before {
			background: $primary_color !important;
			border-radius: 4px;
		}

		.e-square-off-btn {
			position: absolute;
			top: 38%;
			right: 15%;
			padding: 12px 16px;
			border-radius: 4px;
			pointer-events: none;
		}
		.e-square-off-active {
			position: absolute;
			top: 38%;
			right: 15%;
			padding: 14px 16px;
			border-radius: 9px;
			width: 45%;
		}
		.e-pending-tag {
			color: #ffffff;
			font-weight: 400;
			line-height: 120%;
			text-align: center;
			padding: 7px 16px;
			width: max-content;
			background-color: #af9214;
			border-radius: 100px;
			z-index: 10;
			position: relative;
			font-size: 12px;
		}
	}
	.e-skillrace-checkbox {
		input:checked ~ .e-check-mark {
			background-color: $primary_color !important;
		}
		.e-check-mark:after {
			border-color: #0d041d;
		}
	}

	.radioButton_e_radiobutton__XQMbA label {
		color: $white !important;
		font-size: 12px !important;
		padding: 2px 0px 0 27.78px;
		font-weight: 300 !important;
	}
}
.e-my-activity-wrap {
	.e-square-off-btn {
		right: 7px !important;
	}
	.e-square-off-active {
		width: 9% !important;
		right: 7px !important;
	}
}

#viewResults .modal-dialog {
	max-width: 1000px !important;
}
.e-watchlist-logo-wrap {
	width: 30px;
	height: 30px;
	background-color: #fff;
	border-radius: 100px;
	border: 1px solid #e9ebfd;
	display: flex;
	justify-content: center;
	align-items: center;
}
.e-watch-logo {
	width: 24px;
	height: 24px;
	border-radius: 100px;
	object-fit: contain;
	margin: 0 !important;
}
.e-activity-logo {
	.e-watchlist-logo-wrap {
		width: 24px;
		height: 24px;
		border: 1px solid #e9ebfd;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.e-watch-logo {
		width: 20px;
		margin: 0 !important;
		height: 20px;
		object-fit: contain;
	}
}
.slick-prev {
	background-image: url("../assets/images/header/slick-next.svg") !important;
	background-position: 100% 100% !important;
	background-repeat: no-repeat !important;
	background-size: 48px !important;
	width: 48px !important;
	height: 48px !important;
	rotate: 180deg;
	top: 37% !important;
	z-index: 10;

	&:hover {
		background-image: url("../assets/images/header/slick-next.svg");
		background-position: 100%;
		background-repeat: no-repeat;
		background-size: 48px;
		width: 48px;
		height: 48px;
		rotate: 180deg;
		top: 37% !important;
		z-index: 10;
	}

	&:before {
		display: none;
	}

	&:focus {
		background-image: url("../assets/images/header/slick-next.svg");
		background-position: 100%;
		background-repeat: no-repeat;
		background-size: 48px;
		width: 48px;
		height: 48px;
		rotate: 180deg;
		top: 37% !important;
		z-index: 10;
	}
}
.slick-disabled {
	opacity: 0.5 !important;
	cursor: no-drop !important;
}

.slick-next {
	background-image: url("../assets/images/header/slick-next.svg") !important;
	background-position: 100% 100% !important;
	background-repeat: no-repeat !important;
	background-size: 48px !important;
	width: 48px !important;
	height: 48px !important;

	&:hover {
		background-image: url("../assets/images/header/slick-next.svg");
		background-position: 100%;
		background-repeat: no-repeat;
		background-size: 48px;
		width: 48px;
		height: 48px;
	}

	&:before {
		display: none;
	}

	&:focus {
		background-image: url("../assets/images/header/slick-next.svg");
		background-position: 100%;
		background-repeat: no-repeat;
		background-size: 48px;
		width: 48px;
		height: 48px;
	}
}

.slick-slide img {
	display: unset !important;
}

.slick-slider .slick-list {
	width: 100%;
}

.slick-slider {
	padding: 0;
}

#overlay {
	position: fixed;
	/* Sit on top of the page content */
	width: 100%;
	/* Full width (cover the whole page) */
	height: 100%;
	/* Full height (cover the whole page) */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.9);
	/* Black background with opacity */
	z-index: 1;
	/* Specify a stack order in case you're using a different order for other elements */
	cursor: pointer;
	/* Add a pointer on hover */
}

.e-game-result-modal {
	.tab_e_tab__7S04C {
		color: $placeholder !important;
		@extend %font14;
		line-height: 120%;
		font-weight: 600;
		font-family: $font-primary;
	}

	.tab_e_active_tab__yWrKC {
		color: $white !important;
		@extend %font14;
		line-height: 120%;
		font-weight: 600;
		font-family: $font-primary;
		padding-bottom: 2px;
		border-bottom: 2px solid $primary_color;
	}

	.tab_e_tab__7S04C:before,
	.tab_e_active_tab__yWrKC:before {
		content: "";
		position: absolute;
		width: 0;
		height: 2px;
		background-color: $primary_color;
		bottom: 0;
		transition: none;
	}

	.tab_e_active_tab__yWrKC:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 0px !important;
		background-color: transparent !important;
		bottom: 0px;
		left: 0;
	}
}
.e-activity-layout {
	background: #1f1136;
	border-radius: 16px;
	padding: 8px;
}
.e-activity-tab {
	color: $white;
	font-size: 14px;
	font-weight: 500 !important;
	font-family: $font-secondary;
}
.e-activity-inactive {
	opacity: 0.6;
}
.e-active-scrip-update {
	width: 100px !important;
	border: 1px solid #b4b2b8 !important;
	border-radius: 4px !important;
	padding: 6px 29px 6px 10px;
	background-color: #0d041d;
	&::placeholder {
		color: #7d7688;
	}
	&:focus {
		border: 1px solid $primary_color !important;
	}
}
.e-activity-input {
	width: 100px !important;
	border: 1px solid #b4b2b8 !important;
	border-radius: 4px !important;
	padding: 6px 29px 6px 10px;
	background-color: transparent;
	&::placeholder {
		color: #7d7688;
	}
	&:focus {
		border: 1px solid $primary_color !important;
	}
}

.e-active-scrip-update-btn {
	height: 35px !important;
	padding: 8px;
	width: 64px !important;
	border-radius: 4px;
	background-color: $primary_color !important;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
	&::before {
		background: none;
		border-radius: 4px !important;
	}
	&::after {
		border-radius: 4px !important;
	}
}

.e-faded {
	opacity: 0.27 !important;
}

.e-total-added {
	color: #fff;
}

.e-skillrace-table-header {
	color: #7d7688;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 120%;
}
.e-skillrace-table-body {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: #fff;
}
.e-table-column {
	height: 100%;
	padding-top: 10px;
	width: 90%;
}
.e-table-skillrace-result {
	border-radius: 16px;
	.border-bottom {
		color: #7d7688 !important;
		font-weight: 600 !important;
		line-height: 19px !important;
		font-size: 14px;
		border: 2px solid #7d7688;
	}
}
/* the slides */
// .slick-slide {
//   margin: 0 27px;
// }

/* the parent */
// .slick-list {
//   margin: 0 -27px;
// }

/*---------------*/
.e-link-red {
	color: #ab0a0a !important;
	background: none;
	border: none;
	outline: none;
	line-height: 150%;
	position: relative;
	transition: background-size 0.3s linear;
	text-decoration: none;

	background: linear-gradient(to right, #ab0a0a 0%, #ab0a0a 98%);
	background-size: 0px 2px;
	background-repeat: no-repeat;
	background-position: left 85%;
	padding: 0 0 7px 0;

	cursor: pointer;

	&:hover {
		background-size: 100% 2px;
	}
}

.e-skill-race-winners {
	padding: 0 0 14px 0;
	background-position: left 65% !important;
}

.e_table_input {
	width: 40% !important;
	text-align: center;
	padding: 4px 2px 4px 2px !important;
	@extend %font12;
	background: transparent;
	border: none;
	outline: none;
	font-weight: 400;
	color: $white;
	border-radius: 8px;

	&::placeholder {
		font-weight: 400;
		line-height: 18px;
		color: $placeholder;
		@extend %font14;
	}
}

.e-hidden {
	display: none !important;
}

.e-red {
	color: #fa6363 !important;
}

.e-green {
	color: #16bf64 !important;
}

/*---------Privacy policy*/
.e-privacy-wrapper {
	h2 {
		font-size: 40px !important;
		color: $white;
		font-weight: 600;
	}

	p {
		color: $white;
		line-height: 24px;
		opacity: 0.7 !important;
	}
}

// submit modal checkbox
.e-submit-modal-checkbox {
	margin-left: 200px;
	margin-top: 10px;
	color: #fff !important;
}

.e-result-table {
	.sc-dkPtRN,
	.rdt_TableRow {
		border: none !important;
	}
}

.e-result-filter-wrap {
	.e-ant-daterange-picker {
		width: 285px;
	}
	.ant-picker {
		padding: 10px 8px !important;
	}
	.e-result-filter-submit {
		height: 45px;
	}
	.e-select-wraper .css-1s2u09g-control,
	.e-select-wraper .css-1pahdxg-control {
		border: 1px solid transparent !important;
		border-radius: 8px !important;
		background-color: #1f1136 !important;
		padding: 4px 0px 4px 8px !important;
		width: 144px !important;
	}
}
.e-topics {
	font-size: 13px;
	background-image: url("../assets/images/game-detail/more.svg") !important;
	background-repeat: no-repeat !important;
	background-position: 10px 50% !important;
	cursor: grab !important;
}

.e-remove-scrip-btn {
	&:hover {
		cursor: pointer;
	}
}

.e-eye-disabled {
	opacity: 0.5;
	pointer-events: none;
}

// bookings
.e-booking-table {
	.sc-dmRaPn {
		border-radius: 16px !important;
	}

	.rdt_TableRow {
		border-bottom: 1px solid #2b213a !important;
		padding: 10px 0;
	}
}

//  table rounded border
.e-table-rounded-border {
	.sc-dmRaPn {
		border-radius: 32px !important;
	}
}

.e-16rounded-table {
	.sc-dmRaPn {
		border-radius: 16px !important;
	}
}

// ant data picker
.ant-picker-cell-range-start {
	.ant-picker-cell-inner {
		&::before {
			border: 1px solid $primary_color !important;
		}

		background: $primary_color !important;
	}
}

.ant-picker-year-btn,
.ant-picker-month-btn,
.ant-picker-decade-btn {
	&:hover {
		color: $primary_color !important;
	}
}
.ant-picker-cell-selected {
	.ant-picker-cell-inner {
		background-color: $primary_color !important;
	}
}
.ant-picker-cell {
	.ant-picker-cell-inner {
		&:hover {
			background-color: $primary_color !important;
		}
	}
}
.ant-picker-cell-range-end {
	.ant-picker-cell-inner {
		&::before {
			border: 1px solid $primary_color !important;
		}

		background: $primary_color !important;
	}
}

.ant-picker-panel-container {
	// border: 1px solid $primary_color;
	background-color: #1f1136;
}
.ant-picker-panel {
	border: none;
}

// .ant-picker-super-prev-icon {
// 	&::after {
// 		border: 0 solid white !important;
// 	}
// }
.e-v2r-container {
	h2 {
		color: $white;
		opacity: 90%;
		@extend %font36;
		font-family: $font-secondary;
		font-weight: 800;
	}
	.e-type-frequency {
		color: $white;
		opacity: 90%;
		@extend %font16;
		font-family: $font-secondary;
		font-weight: 400;
	}
	.e-bread-crumb {
		color: $white !important;
		opacity: 90% !important;
		@extend %font16;
		font-family: $font-secondary !important;
		font-weight: 400 !important;
	}
	.e-tutorial {
		span {
			color: $white;

			// text-decoration: underline;
			font-weight: 600;
			@extend %font14;
			line-height: 16px;
			border-bottom: 1.5px solid $white;

			cursor: pointer;
		}
		.e-playBtn {
			width: 16px;
			height: 16px;
		}
	}
	.e-v2r-result-btn {
		background: #9c1fe9;
		border-radius: 100px;
		font-weight: 700;
		font-size: 12px;
		line-height: 120%;
		&::before {
			background: transparent;
		}
		&::after {
			background: transparent;
		}
	}
	.e-card-wrappper {
		background: $secondary_color;
		border-radius: 24px;
		height: 500px;
		overflow-y: scroll;
		overflow-x: hidden;
		padding-bottom: 30px;
	}
	.e-scrip-code-v2r {
		font-weight: 500;
		font-size: 14px;
		line-height: 140%;
		color: $white;
	}
	// .e-scrip-container {
	// }
	.e-scrip-card {
		border-bottom: solid 1px #2b213a;
		cursor: pointer;
		margin: 0 14px;
		padding: 8px 0px;
		&:hover {
			background-color: #0d041d;
			margin: 0;
			padding: 8px 14px !important;
		}
	}
	.e-action {
		background: #434343;
		border-radius: 100px;
		padding: 4px 8px;
		font-family: $font-primary;
		font-weight: 500;
		font-size: 12px;
		line-height: 140%;
		color: $white;
		margin-left: 4px;
	}
	.e-action-depth {
		background: #434343;
		border-radius: 100px;
		padding: 4px 8px;
		font-family: $font-primary;
		font-weight: 500;
		font-size: 12px;
		line-height: 140%;
		color: $white;
		margin-left: 4px;
	}
	.e-action-buy {
		background: $primary_color;
		border-radius: 100px;
		padding: 4px 8px;
		font-family: $font-primary;
		font-weight: 500;
		font-size: 12px;
		line-height: 140%;
		color: $white;
	}
	.e-action-sell {
		background: #ff380d;
		border-radius: 100px;
		padding: 4px 8px;
		font-family: $font-primary;
		font-weight: 500;
		font-size: 12px;
		line-height: 140%;
		color: $white;
		// margin-left: 4px;
	}
	.e-action-icon {
		cursor: pointer;
		transition: 0.3s ease-in-out;
		&:hover {
			transform: scale(1.15);
		}
	}

	.search_e_search_wrapper__8i3JA {
		background-color: $secondary_color !important;
	}

	.e-market-section-card {
		background: #160730;
		border-bottom: solid 1px #2b213a;
		border-top: solid 1px #2b213a;
		margin: 0;
		padding: 24px;
		margin-top: -1px;

		.e-title {
			font-weight: 500;
			font-size: 12px;
			line-height: 120%;
			color: #7d7688;
			padding-bottom: 20px;
		}
		.e-buy {
			font-weight: 500;
			font-size: 14px;
			line-height: 120%;
			color: $primary_color;
			padding-bottom: 20px;
		}
		.e-sell {
			color: #ff380d;
			font-weight: 500;
			font-size: 14px;
			line-height: 120%;
			padding-bottom: 20px;
		}
		.e-border-right {
			border-right: solid 1px #404040;
		}
		.e-border-between {
			position: relative;
			&::after {
				width: 60%;
				content: "";
				position: absolute;
				height: 2px;
				background-color: #404040;
				bottom: -10px;
				left: 20%;
			}
		}
		.e-table-data {
			.e-column {
				font-weight: 500;
				font-size: 12px;
				line-height: 120%;
				color: #7d7688;
			}
			.e-column-data {
				font-weight: 500;
				font-size: 12px;
				line-height: 120%;
				color: #ffffff;
			}
		}
	}
	.e-sort-section {
		background: #1f1136;
		box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
		border-radius: 24px;
		padding: 24px 24px 14px 24px;
		position: absolute;
		right: -130px;
		width: 146px;
		top: 60px;
		z-index: 10;
		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			color: $white;
			cursor: pointer;
			margin-bottom: 16px;
			&:hover {
				color: $primary_color;
			}
		}
	}
	.e-v2r-container-tabs {
		.e-tab {
			font-size: 18px !important;
			font-weight: 600 !important;
		}
	}
	.e-buy-btn {
		background: #4a06b8;
		border-radius: 100px;
		padding: 10px 16px;
		font-family: $font-secondary;
		font-weight: 700;
		font-size: 12px;
		color: $white;
		border: none;
		cursor: pointer;
	}
	.e-sell-btn {
		background: #ff380d;
		border-radius: 100px;
		padding: 10px 16px;
		font-family: $font-secondary;
		font-weight: 700;
		font-size: 12px;
		color: $white;
		border: none;
		cursor: pointer;
	}

	.e-v2r-tabs {
		.e-active-tab {
			background: linear-gradient(90deg, #9c1fe9 0%, #4a06b8 100%) !important;
			border-radius: 100px;
			padding: 10px 16px;
			position: inherit;
			bottom: 10px;
			width: max-content;
			font-weight: 700;
			font-size: 12px;
			line-height: 120%;
			text-align: center;
			color: #ffffff;
			margin-right: 0px !important;
			&::before,
			&::after {
				height: 0px !important;
			}
		}
		.e-inactive-tab {
			background: $black;
			border-radius: 100px;
			padding: 10px 16px;
			position: inherit;
			bottom: 10px;
			width: max-content;
			font-weight: 700;
			font-size: 12px;
			line-height: 120%;
			text-align: center;
			color: #ffffff;
			margin-right: 0px !important;
			&::before,
			&::after {
				height: 0px !important;
			}
		}
	}
	.e-participants {
		.e-skillrace-score {
			font-weight: 600;
			@extend %font12;
			line-height: 15px;
			color: #7d7688;
			margin-right: 29px;

			span {
				color: $white;
			}
		}
	}

	.e-summary-section {
		h5 {
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			color: $white;
		}
		.e-summary-card {
			background: #0d041d;
			border-radius: 16px;
			padding: 16px;
			height: 100%;
			h6 {
				font-weight: 400;
				font-size: 14px;
				line-height: 120%;
				color: $white;
				margin-bottom: 20px;
			}
			p {
				font-weight: 700;
				font-size: 16px !important;
				line-height: 20px;
				color: $white;
				margin-bottom: 0px;
			}
			.e-pie-label {
				min-width: 10px;
				height: 10px;
				display: inline-block;
				border-radius: 100%;
				margin-right: 10px;
			}
			.e-pie-label-title {
				font-weight: 500 !important;
				font-size: 12px !important;
				line-height: 15px !important;
				color: $white !important;
				margin-bottom: 10px;
			}
		}
		.e-leaderboard-label {
			font-weight: 600;
			font-size: 14px;
			line-height: 120%;
			color: #7d7688;
		}
		.e-leaderboard-label-data {
			font-weight: 400;
			font-size: 14px;
			line-height: 120%;
			color: #ffffff;
		}
	}

	.e-holdings-section {
		h5 {
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			color: $white;
		}
		.e-holdings-search {
			padding: 8px 32px 8px 36px !important;
		}
		.e-suffix {
			top: 22%;
			left: 6%;
			width: 16px;
		}
		.rdt_TableCell {
			cursor: pointer;
		}
		.e-sort-holdings-section {
			background: #1f1136;
			box-shadow: 2px 2px 48px rgba(0, 0, 0, 0.16);
			border-radius: 24px;
			padding: 24px 24px 14px 24px;
			position: absolute;
			left: 0;
			width: 146px;
			top: 60px;
			z-index: 10;
			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 19px;
				color: $white;
				cursor: pointer;
				margin-bottom: 16px;
				&:hover {
					color: $primary_color;
				}
			}
		}
		.e-position-total {
			position: absolute;
		}
	}

	.rdt_TableHeadRow,
	.rdt_TableCol {
		background-color: #0d041d !important;
	}
	.rdt_TableCell {
		padding: 8px 14px;
		color: #ffffff;
		font-weight: 500 !important;
		line-height: 140% !important;
		font-size: 14px !important;
		.e-time {
			font-weight: 500;
			font-size: 12px;
			padding-top: 4px;
			line-height: 140%;
			color: #7d7688;
		}
	}
	.e-16rounded-table .sc-dmRaPn {
		height: 50vh;
		background-color: #0d041d;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 6px;
			height: 0px;
		}
		&::-webkit-scrollbar-thumb {
			width: 6px;
			height: 0px;
			background-color: $primary_color;
		}
		// scrollbar-width: none;
	}
	.e-watchlist-tabs {
		width: 100%;
		white-space: nowrap;
	}
	.e-watchlist-scroll {
		overflow-x: scroll;
		overflow-y: hidden;
		padding: 12px 0;
	}

	.e-order-section {
		.e-v2r-tab {
			background: #1f1136;
		}
		.e-buy {
			padding: 8px 14px;
			background: #01c55b;
			border-radius: 100px;
		}
		.e-sell {
			padding: 8px 14px;
			background: #ff380d;
			border-radius: 100px;
		}

		.e-table-action {
			background: #000000;
			border-radius: 100px;
			padding: 8px 10px;
			margin-right: 10px;
			font-weight: 700;
			font-size: 12px;
			line-height: 120%;
			text-align: center;
			color: #ffffff;
			cursor: pointer;
		}
	}
	.e-end-time {
		background: $black;
		border-radius: 100px;
		padding: 10px 16px;
		position: inherit;
		bottom: 10px;
		width: max-content;
		font-weight: 700;
		font-size: 12px;
		line-height: 120%;
		text-align: center;
		color: #ffffff;
		font-weight: 700 !important;
	}

	.e-border-bottom {
		border-bottom: 1px solid $secondary_color;
	}

	.tab_e_active_tab__R3Vxj {
		font-weight: 600;
		@extend %font18;
	}

	.tab_e_tab__AKX3n {
		font-weight: 600;
		@extend %font18;
	}

	.e-watchlist-avg {
		p {
			font-weight: 300;
			@extend %font14;
			line-height: 32px;
			color: $white;
			margin-right: 15px;

			span {
				font-weight: 600;
				@extend %font20;
			}
		}

		.e-text-btn {
			color: $primary_color;
			@extend %font16;
			font-weight: 600;
		}

		.e-text-btn-active {
			color: $white;
			@extend %font16;
			font-weight: 600;
			transition: ease 0.3s;
			padding-bottom: 2px;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $primary_color;
				bottom: 0px;
				left: 0;
			}
		}
	}
	.e-sell-price {
		// padding: 10px;
		// text-align: center;
	}
	.e-exit-game {
		color: #fa6363;
		margin-left: 15px;
		font-weight: 600;
	}
	.e-preferred-wraper {
		background: $secondary_color;
		border-radius: 24px;
		padding: 32px;
		height: 100%;
		h5 {
			@extend %h5;
		}

		.e-reward-time {
			font-weight: 400;
			font-size: 14px;
		}

		.e-span-reward {
			color: #d9a214;
		}

		label {
			font-weight: 400;
			@extend %font14;
			line-height: 120%;
			color: #7d7688;
			display: block;
		}

		p {
			font-weight: 600;
			@extend %font14;
			line-height: 140%;
			color: $white;
		}

		.e-bookings {
			color: $white;
			font-weight: 400;
		}
	}
	.e-remove-activity {
		cursor: pointer;
		transition: ease 0.5s;
		&:hover {
			transform: scale(0.8);
		}
	}
	.e-activities-warpper {
		background: #0d041d;
		border-radius: 16px;
		padding: 8px;

		label {
			font-weight: 600;
			@extend %font14;
			line-height: 120%;
			color: #7d7688;
			margin-top: -2px;
		}

		p {
			font-weight: 400;
			@extend %font14;
			line-height: 140%;
			color: $white;
		}

		.e-positive {
			color: #16bf64;
		}

		.e-icon {
			font-weight: 400;
			@extend %font14;
			line-height: 140%;
			color: $white;
		}

		.e-sl-input {
			border: 2px solid #7d7688;
			width: 85px;
			padding: 4px 8px !important;
			font-size: 14px;
			border-radius: 4px;
		}

		.primary_e_p_full_width_btn__dpLjR {
			padding: 8px 11px;
		}

		.primary_e_p_btn__\+SQh7 {
			padding: 6px 11px;
		}

		.primary_e_p_btn__\+SQh7 {
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			color: $white;
			text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		}

		.primary_e_p_btn__\+SQh7::before {
			background: $primary_color !important;
			border-radius: 4px;
		}

		.e-square-off-btn {
			position: absolute;
			top: 38%;
			right: 15%;
			padding: 12px 16px;
			border-radius: 4px;
			pointer-events: none;
		}
		.e-square-off-active {
			position: absolute;
			top: 38%;
			right: 15%;
			padding: 14px 16px;
			border-radius: 9px;
			width: 45%;
		}
		.e-pending-tag {
			color: #ffffff;
			font-weight: 400;
			line-height: 120%;
			text-align: center;
			padding: 7px 16px;
			width: max-content;
			background-color: #af9214;
			border-radius: 100px;
			z-index: 10;
			position: relative;
			font-size: 12px;
		}
	}
	.e-skillrace-checkbox {
		input:checked ~ .e-check-mark {
			background-color: $primary_color !important;
		}
		.e-check-mark:after {
			border-color: #0d041d;
		}
	}

	.radioButton_e_radiobutton__XQMbA label {
		color: $white !important;
		font-size: 12px !important;
		padding: 2px 0px 0 27.78px;
		font-weight: 300 !important;
	}
}

.ant-picker-super-prev-icon,
.ant-picker-super-next-icon,
.ant-picker-next-icon,
.ant-picker-prev-icon {
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 7px;
		height: 7px;
		border: 0 solid $primary_color;
		border-width: 1.5px 0 0 1.5px;
		content: "";
	}
}
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
	&::after {
		position: absolute;
		top: 4px;
		left: 4px;
		display: inline-block;
		width: 7px;
		height: 7px;
		border: 0 solid $primary_color;
		border-width: 1.5px 0 0 1.5px;
		content: "";
	}
}

.ant-picker-header {
	color: #fff;
	border-bottom: 1px solid $primary_color;
}

.ant-picker-content {
	th {
		color: #fff !important;
	}
}

.ant-picker-cell {
	color: #ccc;
	opacity: 0.5;
}

.ant-picker-cell-in-view {
	color: #fff !important;
	opacity: 1 !important;
}

.ant-picker-cell-disabled {
	color: #ccc !important;
	opacity: 0.5 !important;
}

.ant-picker-cell-in-range {
	&::before {
		background: $primary_color !important;
	}
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single) {
	&:before {
		background: $primary_color !important;
	}
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single) {
	&:before {
		background: $primary_color !important;
	}
}
.ant-picker-clear {
	color: $primary_color !important;
	background-color: #1f1136 !important;
	&:hover {
		color: $primary_color !important;
	}
}
// .ant-picker-prev-icon::before, .ant-picker-next-icon::before, .ant-picker-super-prev-icon::before, .ant-picker-super-next-icon::before{
// 	border : 0 solid white!important;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	display: inline-block;
// 	width: 7px;
// 	height: 7px;
// 	border-width: 1.5px 0 0 1.5px;
// 	content: '';
// }

.e-green-bg {
	background-color: #1f3b22 !important;
}

.e-red-bg {
	background-color: #5a1b1e !important;
}

.e-results-modal-row {
	background-color: #0d041d !important;
}
.e-overflow {
	overflow-y: auto;
	white-space: nowrap;
}

// pending overlay banner
.e-pending-wrapper {
	&::after {
		content: "";
		background-color: #1f1136;
		opacity: 0.95;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 16px;
		z-index: 10;
	}
	.e-content {
		z-index: 11;
		position: absolute;
		top: 150px;
	}
	h6 {
		color: #ffffff;
		line-height: 150%;
		font-size: 24px;
		font-weight: 600;
	}
	p {
		color: rgba(255, 255, 255, 0.9);
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 150%;
		z-index: 10;
	}
}

// Reports accordion

.e-reports-filter-wrapper {
	.e-select-wraper .css-1s2u09g-control,
	.e-select-wraper .css-1pahdxg-control {
		border: 1px solid transparent !important;
		border-radius: 8px !important;
		background-color: #1f1136 !important;
		padding: 4px 0px 4px 8px !important;
		width: 100% !important;
		min-height: 50px !important;
	}
	.e-select-wraper .css-26l3qy-menu {
		z-index: 2;
	}
}
.e-result-accordion {
	.ant-picker {
		height: 50px;
	}
}
.e-check-box-checkmark {
	margin-top: 2px;
}
.e-g-dashboars-gif {
	width: 50px;
	height: 50px;
	background-color: #fef4ab;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 50px;
		height: 50px;
	}
	.e-ratig-gif {
		width: 120px;
		height: max-content;
	}
}

//pending detail
.e-pending-detail {
	padding: 100px 0px;
	img {
		height: 200px;
	}
	h6 {
		color: $white;
		font-size: 24px;
		font-weight: 600;
	}
	p {
		color: $white;
		opacity: 0.6;
	}
}

// filters

.e-filter {
	display: inline-block;
}
.e-download-booking {
	float: right;
	top: 45px;
}
.e-m2e-table-row {
	line-height: 15px !important;
}
// m2e result modal
.e-result-modal-data-wrapper {
	// display: flex;
	justify-content: space-between;
}
.e-result-data {
	display: inline-block;
	width: 12%;
}
.e-image-bull {
	position: relative;
	z-index: 2;
}
.e-m2e-detail-toggle {
	top: -2.5px;
}

// 404 page
.e-404-page {
	background-color: #1f1136;
	border-radius: 24px;
	h5 {
		font-weight: 600;
		font-size: 20px;
		line-height: 120%;
		text-align: center;
		color: #ffffff;
	}
	p {
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		text-align: center;
		color: #7d7688;
	}
	.e-404-image {
		width: 550px;
		height: 420px;
		margin: auto;
		object-fit: cover;
	}
	.e-submit {
		width: 195px;
		margin: auto;
	}
}
.e-profile-download {
	img {
		width: 20px;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px #372740 inset !important;
	-webkit-text-fill-color: #fff !important;
}

.e-result-btn {
	padding: 6px 12px;
	font-size: 14px !important;
	height: 34px;
	&:hover {
		box-shadow: 4px 4px 14px rgb(154 30 232 / 48%) !important;
	}
}
.e-m2m-slot-count {
	width: 32px;
	height: 32px;
	border-radius: 100px;
	background: linear-gradient(90deg, #9c1fe9 0%, #4a06b8 100%);
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	font-feature-settings: "kern" off;
	color: $white;
	padding: 10px;
}
