
.e_nodata_wrapper{
    .e_img{
        width: 140px;
        height: 140px;
    }
    .e_title{
        font-family: 'Inter';
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        text-transform: capitalize!important;
        color: #fff;
    }
    .e_note{
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #7D7688;
    }
}