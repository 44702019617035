/*
 *   File : tab.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Tab Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 25-01-2022
 */

@import "./../config.scss";

.e_tab {
    @extend %font24;
    font-family: $font-primary;
    color: $white;
    transition: ease 0.3s;
    padding: 8px 16px;
    background: #0d041d;
    border-radius: 8px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 123.6%;

    &:hover {
        &:before {
            width: 100%;
        }
        cursor: pointer;
    }
}
.e_active_tab {
    @extend .e_tab;
    transition: ease 0.3s;

    color: $white;
    font-weight: 400;
    background: linear-gradient(90deg, #9c1fe9 0%, #4a06b8 100%);
    border-radius: 8px;
}
