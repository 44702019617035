@import "./../config.scss";

.e_checkbox_container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  color: $white;
  transition: ease 0.5s;
  padding-top: 3px;
  &:hover {
    // color: $primary_color;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /*  custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1.5px solid $white;
    box-sizing: border-box;
    transition: all 0.5s;
    border-radius: 2px;
    color: transparent;
    &:hover {
      border: 1px solid $primary_color;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 0px;
      width: 5px;
      height: 11px;
      border: solid $primary_color;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  /* custome checkmark */
  input:checked ~ .checkmark:after {
    display: block;
  }
  input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid $primary_color;
  }
}
.e_disabled_check {
  pointer-events: none;
  opacity: 0.5;
  .checkmark {
    opacity: 0.5;
  }
}
.e_v2r_checkbox_container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  color: $white !important;
  transition: ease 0.5s;
  padding-top: 3px;
  &:hover {
    // color: $primary_color;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /*  custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 10.5px;
    width: 10.5px;
    border: 1.5px solid $white;
    box-sizing: border-box;
    transition: all 0.5s;
    border-radius: 2px;
    color: transparent;
    &:hover {
      border: 1px solid $primary_color;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 2px;
      height: 6px;
      border: solid $primary_color;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  label {
    color: $white !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  /* custome checkmark */
  input:checked ~ .checkmark:after {
    display: block;
  }
  input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid $primary_color;
  }
}
.e_disabled_check {
  pointer-events: none;
  opacity: 0.5;
  .checkmark {
    opacity: 0.5;
  }
}
