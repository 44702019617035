@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Stock Sports
Version:    1.0.0
Created:    13-01-2022
Use:
-------------------------------------------------------------------*/

/*--- 1. Import Fonts ----*/

$font-primary: "Inter";
$font-secondary: "Sora";

@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-primary;
  src: url(../assets/fonts/Inter/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: $font-secondary;
  src: url(../assets/fonts/Sora/static/Sora-Light.ttf);
  font-weight: 300;
}

/* --- 2. Colors --- */
$primary_color: #9c1fe9;
$primary_gradient: linear-gradient(90deg, #9c1fe9 0%, #4a06b8 100%);
$primary_hover: linear-gradient(90deg, #4a06b8 0%, #9c1fe9 100%);
$secondary_color: #1f1136;
$secondary_dark: #130727;
$green: #0e934b;
$red: #b02323;
$dark_green: #0a4425;
$dark_red: #781717;
$error: #ff523b;
$result: #fa6363;
$white: #ffffff;
$black: #000000;
$placeholder: #7d7688;
$toast_success_border: #00d1b1;
$toast_success_bg: #d4ece8f5;
$toast_success_color: #089487;
$toast_fail_bg: #f9e5e5;
$modal_bg: #0d041d;
$footer_year: #a3a3a3;
$light_green: #008d41;
$menu_bg: #130727;
$status_pending: #f2994a;
$blue: #15008b;
$yellow: #d9a214;
$bg_light: #e5e5e5;
$footer_bg_light: #f2f2f2;
$light_theme_bg: #fafafa;

/* --- 3. Font --- */
%font10 {
  font-size: 0.625rem;
}
%font11 {
  font-size: 0.688rem;
}
%font12 {
  font-size: 0.75rem;
}
%font14 {
  font-size: 0.875rem;
}
%font16 {
  font-size: 1rem;
}
%font18 {
  font-size: 1.125rem;
}
%font20 {
  font-size: 1.25rem;
}
%font24 {
  font-size: 1.5rem;
}
%font32 {
  font-size: 2rem;
}
%font36 {
  font-size: 2.25rem;
}
%font48 {
  font-size: 3rem;
}

/* --- 2. Global --- */
%h3 {
  @extend %font32;
  color: $white;
  font-weight: 700;
}
%h4 {
  @extend %font24;
  color: $white;
  font-weight: 700;
}
%h5 {
  @extend %font18;
  color: $white;
}
%p {
  @extend %font16;
  font-weight: 300;
  color: $white;
  padding-right: 50px;
}
.e-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.e-primary-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.modal-content {
  background-color: $secondary_color !important;
  // border-radius: 32px!important;
}
.btn-close {
  background-image: url("../assets/images/modal/close.svg");
  // background-position: 100% 105%;
  background-repeat: no-repeat;
  opacity: 10 !important;
  &:hover {
    opacity: 0.7 !important;
  }
}
.btn-close-dark {
  background-image: url("../assets/images/modal/close-dark.png");
  // background-position: 100% 105%;
  background-repeat: no-repeat;
  opacity: 10 !important;
  background-size: 15px;
  border: none;
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  background-color: transparent;
  &:hover {
    opacity: 0.7 !important;
  }
}
.btn-close:focus,
.btn-close-black:focus {
  box-shadow: none !important;
}
.modal-backdrop {
  z-index: 18 !important;
}

/* --- Scroll bar ---*/
::-webkit-scrollbar {
  border-radius: 8px;
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: $primary_color;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
}
