/*
 *   File : welcome.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Welcome Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 25-01-2022
 */

@import "./../config.scss";

.e_welcome_modal {
  .e_modal_dialog {
    max-width: 1078px!important;
    margin-top: 0;
  }
  .e_modal_content {
    padding: 25px 10px !important;
    border-radius: 32px !important;
    min-height: 100vh;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 1rem !important;
	  width: 100%!important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    .e_input_bg {
      background: $modal_bg;
    }
    .e_content_wrapper {
     
      border-radius: 24px;
      padding: 24px; background-color: $modal_bg;
      height: 85vh;
      overflow-y: auto !important;
      &::-webkit-scrollbar {
        display: none;
      }
	  .e_main_logo_section{
		  height: 240px;
		  background-color: $secondary_color;
		  border-radius: 24px;
		  position: relative;
			.e_main_logo{
				width: 240px;
				margin-top: 102px;
			}
			.e_right_img{
				width: 215px;
				position: absolute;
				right: 0;
				top: 0px;
				border-radius: 0px 16px;
			}
			.e_left_img{
				width: 241px;
				position: absolute;
				bottom: 0;
				left: 0;
			}
	  }
      .e_banner {
        width: 100%;
      }
      p {
        color: $white;
        font-weight: 400;
        @extend %font16;
        line-height: 180%;        
      }
      .e_arrow {
        position: fixed;
        left: 80%;
        top: 80%;
      }
      .e_btn {
        border: none;
        outline: none;
        background: $primary_gradient;
        color: $white;
        font-weight: 600;
        @extend %font16;
        line-height: 150%;
        border-radius: 8px;
        padding: 12px 24px;
        &:hover {
          background:$primary_hover;
          transition: 0.4s ease-in-out;
          // box-shadow: 2px 2px 48px rgba(194,134,231);
          box-shadow: 4px 4px 24px rgba(154, 30, 232, 0.48);
        } 
      }
    }
	.e_user_name{
		color: $white;
		font-family: Sora;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 150%;
		width: max-content;
		margin: auto;
	}
	.e_bull{
		height: 100px;
		margin-left:  13px;
		z-index: 2;
		position: relative;
	}
	.e_animation_wrapper{
		margin: auto;
		width: 105px;
	}
	.e_img_margin{
		animation: bounce 0.5s forwards;
		// animation-delay: 0.5s;
	}
	.e_animate_img{
		animation:
			img-zoom-in 0.5s forwards,
			img-zoom-out 2s 2s forwards;
			// animation-fill-mode: forwards;
			// animation: img-zoom-out 2s 1s forwards;
	}

	.e_animate_img_small{
		animation:
			img-zoom-in_mobile 0.5s forwards,
			img-zoom-out_mobile 2s 2s forwards;
			// animation-fill-mode: forwards;
			// animation: img-zoom-out 2s 1s forwards;
	}

	// animation for image initial zoom in
	@keyframes img-zoom-in {
		0%{
			height: 100px;
			margin-left: 125px;
		}
		100%{
			height: 300px;
			margin-left: 125px;
		}
	}
	@keyframes  img-zoom-in_mobile {
		0%{
			height: 50px;
			margin-left: 175px;
		}
		100%{
			height:150px;
			margin-left: 175px;
		}
	}

	@keyframes img-zoom-out_mobile {
		0%{
			position: absolute;
			height: 150px;
			left: 15%;
			top: 15%;
			
		}
	
		100%{
			position: absolute;
			height: 50px;
			left: -30%;
			top: 10%;
		}
	}
	// animation for zoom out
	@keyframes img-zoom-out {
		0%{
			position: absolute;
			height: 350px;
			left: 20%;
			top:40%;
			
		}
	
		100%{
			position: absolute;
			height: 100px;
			left: -10%;
			top: 10%;
		}
	}

	// animation for name,
	@keyframes bounce {
		0%, 12.5% {
			position: absolute;
			top: 50px;
		}
		25% {
			position: absolute;
			top: 50px;
		}
		50% {
			position: absolute;
			top: 115px;
		}
		75%, 100% {
			position: absolute;
			top: 115px;
			// text-align: center;
		}
	}
  }
  .e_main_content{
	  opacity: 0;
	  animation: fade-in 0.5s forwards;
	//   animation-delay: 1s;
  }
  @keyframes fade-in {
	0%{
		opacity: 0;
	}
	25%{
		opacity: 0.3;
	}
	50%{
		opacity: 0.5;
	}
	75%{
		opacity: 0.7;
	}
	100%{
		opacity: 1;
	}
  }
}
