@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1400px) {
	//
	.e-mobile-hand-burger-menu {
		display: none !important;
	}

	//home
	.game-card_e_game_card__vnQlh .game-card_e_timer__c1Uhm {
		padding: 10px 9px;
	}

	.game-card_e_game_card__vnQlh {
		height: 490px;
	}

	.e-skillracetimer {
		display: block;
		width: max-content;
		// float: right;
	}

	.e-skill-race-container .e-activities-warpper label {
		font-size: 13px !important;
	}

	.e-active-scrip-update {
		width: 100% !important;
	}

	.e-skill-race-container .e-activities-warpper .e-square-off-active {
		right: 0 !important;
		padding: 14px 6px !important;
		top: 45% !important;
		width: 50%;
	}

	.e-my-activity-wrap .e-square-off-btn {
		right: 4px !important;
		padding: 12px 6px !important;
		top: 45% !important;
	}

	.e-report-summary {
		min-height: 110px !important;
	}

	.e-scroll {
		white-space: inherit !important ;
	}
	.e-download-booking {
		float: none;
		top: 0;
		margin-top: 20px;
	}

	.e-wallet-card .e-amount {
		font-size: 20px !important;
	}
}

@media only screen and (max-width: 1370px) {
	.rdt_TableCol,
	.rdt_TableCell {
		font-size: 13px !important;
	}
	.e-v2r-tab,
	.e-v2r-active-tab {
		padding: 6px 15px;
	}

	.e-v2r-edit {
		width: 16px;
	}

	.e-participants {
		justify-content: start !important;
	}
	.e-v2r-data {
		display: flex !important;
		align-items: center;
	}
	.e-wallet-card .e-amount {
		font-size: 17px !important;
	}
	.e-global-rank-he {
		font-size: 12px !important;
	}
}

@media only screen and (max-width: 1300px) {
	.iheader_e_header__bjleM .iheader_e_header_ul__5oTNQ .iheader_e_ac_balance__vxefc {
		display: none;
	}
}

@media only screen and (max-width: 1200px) {
	// body
	.e-body {
		padding-top: 0 !important;
	}

	// header
	.e-mob-header-show {
		display: block !important;
		// height: 18vh;
	}

	.e-mobile-hand-burger-menu {
		display: block !important;
		width: 50%;
	}

	.e-lg-header-show {
		display: none !important;
	}

	.iheader_e_header__bjleM .iheader_e_agent_wrap_sm__ba29g {
		display: none !important;
	}

	.e-welcome-modal-left-img,
	.e-welcome-modal-right-img {
		width: 200px !important;
	}
	// .homeEarnings_e_user_image_wrapper__GXovp {
	// 	width: 61px!important;
	// 	height: 54px!important;
	// }
	// .homeEarnings_e_user_image__HUa38{
	// 	width: 45px!important;
	// 	height: 45px!important;
	// }
	.e-home-earnings-name {
		font-size: 20px !important;
	}
	.e-global-rank-he {
		display: block !important;
	}

	// Wallet
	.e-amount-card .e-add-money {
		width: 140%;
	}

	// news
	.news_e_news_card__-R\+F\+ h6 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.iheader_e_header__bjleM .iheader_e_agent_wrap_sm__ba29g {
		display: none;
	}

	.e-end-time {
		display: block;
		width: max-content;
		// float: right;
	}

	.e-header-logo,
	.e-footer-logo {
		width: 180px !important;
		height: 40px !important;
		object-fit: contain;
	}

	.e-game-result-modal {
		.modal-dialog {
			max-width: 950px !important;
		}
	}
	.e-v2r-tab,
	.e-v2r-active-tab {
		padding: 8px 18px;
	}
}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {
	/*Skill race table*/
	.e-mobile-scroll-table {
		overflow-x: scroll;
		overflow-y: hidden;
	}
	.e-participants {
		display: flex;
	}

	.iheader_e_header__bjleM .iheader_e_agent_wrap_sm__ba29g {
		display: none;
	}

	/*Profile*/
	.e-wrap-mob {
		display: block !important;
	}

	.e-side-wrap {
		display: none;
	}
}

@media only screen and (max-width: 992px) {
	// header
	.e-mob-header-show {
		display: unset;
		// height: 18vh;
	}
	.e-v2r-container .e-v2r-tabs .e-inactive-tab {
		margin-right: 8px !important;
	}
	.e-v2r-data {
		min-width: 770px;
	}
	.e-v2r-container .e-sort-section {
		right: 23px;
		background: #0d041d;
	}
	.e-margin-top {
		margin-top: 75px !important;
		padding: 0px;
	}

	.iheader_e_header__bjleM {
		padding: 0 65px;
	}

	.e-lg-header-show {
		display: none;
	}

	.iheader_e_header__bjleM .iheader_e_header_demo__n3h3G {
		display: none;
	}

	.iheader_e_header__bjleM .iheader_e_agent_wrap_sm__ba29g {
		display: none;
	}

	.iheader_e_header__bjleM {
		padding: 0px;
	}

	// Auth
	.e-login-l-wrapper p {
		padding-right: 0px;
	}

	.e-topper-trophy {
		height: 100px !important;
	}

	.game-detail_e_game_detail_modal__RNAvZ .game-detail_e_modal_dialog__EYmYo {
		max-width: 800px !important;
	}

	// game detail modal
	.e-sm-margin {
		margin: 0px;
	}

	.game-detail_e_check_wrapper__AGJeL {
		padding-left: 45px !important;
	}

	.e-md-mt-20 {
		margin-top: 20px;
	}

	.gameDetail_e_game_detail__MSUw2 .gameDetail_e_label__xHALl {
		font-size: 10.5px;
	}

	.e-wallet-card .e-amount {
		font-size: 22px !important;
	}

	// Wallet
	.e-mt-20 {
		margin-top: 20px;
	}

	// Wallet
	.e-amount-card .e-add-money {
		width: 100%;
	}

	// Kyc modal
	.e-upload-clear {
		right: -40px;
	}

	// Profile-settings
	.e-text-left.text-end {
		text-align: left !important;
	}

	.e-milestone-pr {
		padding-right: 10px !important;
	}

	.e-milestone-ps {
		padding-left: 10px !important;
	}

	.e-tab-agent-board {
		display: none !important;
	}

	.e-mobile-agent-board {
		display: block !important;
	}

	//slider
	.slick-next {
		right: -30px;

		&:hover {
			right: -30px;
		}

		&:focus {
			right: -30px;
		}
	}

	.e-m2e-booking-quantity {
		display: block;
	}

	// m2e result modal
	.e-result-data {
		width: max-content;
		margin: 0px 13px;
	}
	.e-game-result-modal {
		.modal-dialog {
			max-width: 680px !important;
		}
	}
	// M2E roound timer
	.CircularProgressbar {
		// position: absolute;
		// right: 0;
		// top: 69%;
	}
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {
	.e-body {
		padding-top: 10.5rem;
	}

	// header
	.e-mobile-hand-burger-menu {
		width: 75%;
	}

	.game-type_e_game_type_modal__DFkwT {
		width: 160px;
		right: -14%;
		left: 0;
	}

	.iheader_e_header__bjleM .iheader_e_agent_wrap__bAOS3 {
		display: none;
	}

	.iheader_e_header__bjleM .iheader_e_agent_wrap_sm__ba29g {
		display: flex !important;
		// width: 95%;
	}

	// Auth
	.e-login-l-wrapper {
		display: none;
	}

	.e-login-r-wrapper {
		width: 95%;
		height: 100vh;
		background-position: right bottom;
	}

	.e-primary-disabled {
		opacity: 10;
	}

	.e-welcome-modal-left-img,
	.e-welcome-modal-right-img {
		width: 160px !important;
	}
	// Home
	.e-small-screen-mt-30 {
		margin-top: 30px;
	}

	.e-topper-trophy {
		height: 80px !important;
	}

	// game detail modal
	.game-detail_e_check_wrapper__AGJeL {
		padding-left: 20px !important;
	}

	.game-detail_e_watch_btn__n45M8 {
		padding: 7px 9px;
	}

	.e-sm-text-left.text-end {
		text-align: unset !important;
	}

	.game-detail_e_game_detail_modal__RNAvZ .game-detail_e_modal_dialog__EYmYo {
		max-width: 600px !important;
	}

	// Profile-settings
	.e-sm-mt-20 {
		margin-top: 20px;
	}

	// Scrip detail
	.e-scrip-detail .e-tab {
		font-size: 16px;
	}

	// Profile
	.e-mobile-scroll {
		overflow-x: scroll;
		overflow-y: hidden;
	}

	.e-profile-tab-wrapper {
		overflow-x: auto;
		white-space: nowrap;
		&::-webkit-scrollbar {
			width: 0px;
		}
	}

	.e-modal-popup {
		max-width: auto !important;
	}
	// M2e game modal
	.e-game-modal-title-wrapper {
		padding: 24px !important;
	}
	.e-game-modal-bull {
		z-index: 2;
		position: relative;
	}
	.e-game-modal-title {
		font-size: 32px !important;
	}
	.e-game-modal-img {
		width: 100px;
		height: 100px;
	}
	// result-modal-m2e
	.e-result-data {
		font-size: 12px;
	}
	// M2E roound timer
	.CircularProgressbar {
		position: relative;
		// top: -30%;
	}

	.e-timer-m2e {
		position: absolute;
		// left: 66%;
		bottom: 10%;
		right: 0%;
	}

	.e-timer-m2m {
		position: absolute;
		right: 0%;
		top: 40%;
	}
	//  game detail card
	.e-game-timer-sec {
		display: flex;
		// justify-content: space-between;
	}

	// .e-game-card-timer{
	// 	display: inline;
	// 	margin-left: 25px;
	// }
	// .e-unit-fee{
	// 	display: inline-block;
	// 	margin-left: 25px;
	// }
	// .e-game-status{
	// 	display: block;
	// }
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
	// home
	.game-card_e_game_card__vnQlh {
		height: 475px;
	}
	.e-margin-top {
		margin-top: 20px !important;
		padding: 0px;
	}

	.slick-next {
		right: -11px !important;
	}
	.slick-prev {
		left: 28px !important;
	}

	.e-start-btn {
		width: 152px !important;
	}
	.e-topper-card {
		p {
			font-size: 14px;
		}
	}

	.e-topper-trophy {
		right: 5px !important;
		top: -45px !important;
		height: 60px !important;
	}

	//  game detail modal
	.game-detail_e_game_detail_modal__KhhVA .game-detail_e_modal_content__PHxJ4 .game-detail_e_content_wrapper__fGJUm {
		max-height: 65vh;
	}

	.game-detail_e_game_detail_modal__KhhVA
		.game-detail_e_modal_content__PHxJ4
		.game-detail_e_content_wrapper__fGJUm
		.game-detail_e_game_wrapper__xuPqA {
		padding: 24px 24px 24px 20px;
	}

	.e-game-detail-wrapper {
		padding: 12px;
	}

	// footer
	.footer_e_footer__TscIE {
		padding: 0 25px;
	}

	//header indices modal
	.e-indices-modal-dialog {
		max-width: 600px !important;
	}

	.e-header-logo {
		width: 140px !important;
		height: 30.4px !important;
		object-fit: contain;
	}

	.e-footer-logo {
		width: 140px !important;
		height: 30.4px !important;
		object-fit: contain;
	}

	.e-home-earnings-name {
		font-size: 16px !important;
	}
	.e-refer-share-text {
		display: none;
	}
	.e-profile-container .e-refer-earn img {
		margin-right: 30px;
		margin-left: 0;
	}
	// m2e scrip submit  modal
	.e-submit-modal-checkbox {
		font-size: 12px !important;
		margin-left: 20px;
	}
	.e-scrip-submit-modal-title {
		font-size: 20px;
	}
	//  game detail card
	.e-game-timer-sec {
		display: table;
		justify-content: none;
	}
	.e-table-cell {
		display: table-cell;
	}
	// game result m2e
	.e-result-modal-data-wrapper {
		display: block;
	}
	.e-welcome-name {
		font-size: 16px !important;
	}
	// 404 page
	.e-404-image {
		width: 250px !important;
		height: 200px !important;
	}
	.e-404-page {
		h5 {
			font-size: 14px;
		}
		p {
			font-size: 12px !important;
		}
	}
}

@media only screen and (max-width: 475px) {
	//  home earnings card
	.e-home-earing-img-wrapper {
		width: 65px !important;
		height: 52px !important;
	}
	.e-watchlist-tabs {
		min-width: 230px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	.e-m2m-stocks {
		font-size: 15px !important;
	}
	.e-timer-m2m {
		position: absolute;
		left: 60%;
		top: 57%;
	}
	.e-home-earing-img {
		width: 43px !important;
		height: 43px !important;
	}
	.e-topper-card .e-placeholder {
		width: 50px;
		height: 50px;
		font-size: 16px;
	}
	.e-gif-wrapper {
		background-color: transparent;
	}
	// m2e edit modal
	.e-edit-scrip-btn-input {
		width: 102px !important;
	}
	.e-edit-modal-symbol {
		font-size: 11px !important;
	}
	.e-m2e-submit-scrip {
		padding: 10px 6px !important;
	}
	.e-welcome-modal-left-img,
	.e-welcome-modal-right-img {
		width: 120px !important;
	}
	// game modal
	.e-game-modal-img {
		width: 80px;
		height: 100px;
	}
	.e-game-modal-content {
		p,
		li {
			font-size: 12px !important;
		}
	}
	.e-v2r-container .e-v2r-leaderboard {
		width: 460px;
	}
	.e-v2r-container .e-summary-section {
		overflow-x: scroll;
	}
	.e-v2r-container {
		.e-watchlist-scroll,
		.e-mobile-scroll-table {
			overflow: hidden;
		}
	}
}

@media only screen and (max-width: 384px) {
	// home game card
	.game-card_e_game_card__p6xvb .game-card_e_margin_top__gEno9 {
		margin-top: 60px;
	}
	.e-game-detail-wrapper h4 {
		font-size: 10px !important;
	}
}

/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {
}
