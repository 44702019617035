/*
 *   File : customizeTable.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Customize Table Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 08-03-2022
 */

 @import "./../config.scss";

 .e_customize_modal {
   .e_modal_dialog {
     max-width: 600px!important;
   }
   .e_modal_content {
     padding: 32px!important;
     border-radius: 32px!important;
     .e_modal_header {
       border-bottom: none!important;
       padding: 0!important;
       h4 {
         @extend %h4;
         font-family: $font-secondary;
         font-weight: 600!important;
       }
     }
     .e_content{
       min-height: 370px;
     }
     label {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;      
      color: #7D7688;
      font-family: $font-primary;
      
     }
     p {
      font-family: $font-primary;
      font-weight: 200;
      font-size: 13px!important;
      line-height: 120%;
      color: #FFFFFF;
     }
     li{
       background-image: url('../../assets/images/game-detail/more.svg');
       background-repeat: no-repeat;
       background-position: 0 70%;
      //  height: 40px;
        
     }
     li::marker {
        // pointer-events: none;
        font-size: 0;
     }
     
   } 
 }
 .e_icon_wrapper{
  padding: 5px;
  cursor:  pointer!important;
 }
// .e_topics{
//   cursor: grab!important;
// } 