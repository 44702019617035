/*
 *   File : kyc.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : KYC Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

@import "./../config.scss";

.e_kyc_modal {
  .e_modal_dialog {
    max-width: 960px!important;
  }
  .e_modal_content {
    padding: 32px!important;
    border-radius: 32px!important;
    .e_modal_header {
      border-bottom: none!important;
      padding: 0!important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    .e_input_bg {
      background: $modal_bg!important;
      input{
        background-color: $modal_bg!important ;
      }
    }
    label {
      font-weight: 300;
      font-size: 12px;
      line-height: 150%;
      color: #FFFFFF;
      font-family: $font-primary;
    }
    
  } 
  .e_profile_card{
    border-radius: 8px;
    background-color: $modal_bg!important;
    h6{
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
    }
    .e_profile_edit_btn{
      width: max-content;
      padding: 12px 14px;
      font-size: 14px;
    }
  }
}
.e_do_kyc_modal {
  .e_modal_dialog {
    max-width: 680px!important;
  }
  .e_modal_content {
    padding: 32px!important;
    border-radius: 32px!important;
    .e_modal_header {
      border-bottom: none!important;
      padding: 0!important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    
    img{
      max-width: 200px;
      max-height: 200px;
    }
    h6{
      font-family: $font-primary;
      @extend %font14;
      line-height: 120%;
      font-weight: 600;
      color: $white;
    }
    .e_complete{
      border: 1px solid #9C1FE9;
    color: #9C1FE9;
    }
  } 
  
}
