/*
 *   File : primary.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Primary Button Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 14-01-2022
 */

@import "./../config.scss";

.e_p_btn {
	border: none;
	outline: none;
	color: $white;
	font-weight: 600;
	@extend %font16;
	line-height: 150%;
	border-radius: 8px;
	font-family: $font-primary;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		background: $primary_gradient;
		border-radius: 8px;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.5s ease-in-out;
	}
	&::after {
		content: "";
		position: absolute;
		background: $primary_hover;
		border-radius: 8px;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.5s ease-in-out;
		opacity: 0;
	}
	&:hover {
		&::after {
			opacity: 1;
		}
		&::before {
			opacity: 0;
		}
		box-shadow: 4px 4px 24px rgba(154, 30, 232, 0.48);
	}
	span {
		z-index: 1;
		position: relative;
	}
}
.e_p_full_width_btn {
	width: 100%;
	padding: 16px 0;
}
.e_p_padding_btn {
	padding: 16px 48px;
}
.e_p_small_btn {
	padding: 10px 16px;
}
.e_p_disabled_btn {
	opacity: 0.7 !important;
	pointer-events: none;
}
.e_loader_img {
	height: 30px;
	z-index: 10;
	position: relative;
}
