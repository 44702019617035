
@import '../config.scss';

.e_modal_content{
    min-height: 400px!important;
    h6{
        color: $white!important;
        margin-top: 20px;
    }
    img{
        margin-top: 50px;
    }
}
.e_modal_header {
    border-bottom: none!important;
  }
.e_modal_dialog {
    max-width: 600px!important;
}