/*
 *   File : buyStock.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Buy Stock Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-03-2022
 */

@import "./../config.scss";

.e_buy_stock_modal {
  .e_modal_dialog {
    max-width: 563px !important;
  }
  .e_modal_content {
    padding: 32px !important;
    border-radius: 32px 20px !important;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 !important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    p {
      color: $white;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
    }
    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #ffffff;
      text-transform: uppercase;
    }
    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #ffffff;
      text-transform: uppercase;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
    }
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #7d7688;
    }
    .e_input {
      //   border: 2px solid #7d7688;
      width: 100%;
      padding: 6px 8px !important;
      font-size: 14px;
      border-radius: 4px;
      border: 2px solid #bebebe;
    }
    .e_disabled_input {
      background-color: #3e3350;
      color: #717171;
      border-color: #3e3350;
    }
    .e_toggle_label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      color: $white !important;
    }
    .e_toggle_label_false {
      color: $white !important;
      opacity: 0.3;
    }
  }
  .e_toggle span {
    // background: #10ff7e !important;
  }
  .toggle_e_slider__6oXkW {
    background-color: red;
  }
}

.e_buy_v2r_stock_modal {
  .e_modal_dialog {
    max-width: 563px !important;
  }
  .e_modal_content {
    padding: 32px !important;
    border-radius: 32px 20px !important;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 !important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    .e_scrip_name {
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      color: $white;
      padding-left: 10px;
    }

    p,
    .e_scrip_detail {
      color: $white;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 120%;
    }
    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #ffffff;
      text-transform: uppercase;
    }
    h5 {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #ffffff;
      text-transform: uppercase;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
    }
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #7d7688;
    }
    .e_input {
      //   border: 2px solid #7d7688;
      width: 100%;
      padding: 6px 8px !important;
      font-size: 14px;
      border-radius: 4px;
      border: 2px solid #bebebe;
    }
    .e_disabled_input {
      background-color: #3e3350;
      color: #717171;
      border-color: #3e3350;
    }
    .e_toggle_label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: $white;
    }
    .e_toggle_label_false {
      color: $white;
      opacity: 0.3;
    }
    .e_cancel {
      padding: 0px !important;
      border-bottom: 1px solid #7d7688 !important;
      color: #7d7688;
      border: none;
      border-radius: 0%;
    }
  }
  .e_toggle span {
    // background: #10ff7e !important;
  }
  .toggle_e_slider__6oXkW {
    background-color: red;
  }
}
