/*
 *   File : loader.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-11-2021
 */

 @import "./../config.scss";

 .e_loader{
   background-color: $secondary_dark;
   height: 30vh;
   display: flex;
   justify-content: center;
   align-items: center;
   img{
     width: 60px;
     height: 60px;
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  margin: -50px 0px 0px -50px;
   }
 
 }
 