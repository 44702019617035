@import "./../config.scss";

.e_progress_track {
  height: 4px;
  background-color: #615e5e;
  width: 100%;
  .e_progress_bar {
    height: 4px;
    background-color: $primary_color;
    border-radius: 12px;
    width: 0;
    -webkit-transition: width 0.8s;
  }
  .e_progress_label {
    background-color: #16bf64;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    // left: 20%;
    top: -2px;
    cursor: pointer;
  }
}
