/*
 *   File : solid.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Solid Button Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 14-01-2022
 */

@import "./../config.scss";

.e_solid_btn {
  outline: none;
  border: none;
  font-weight: 700;
  @extend %font14;
  line-height: 150%;
  border-radius: 4px;
  transition: 0.4s ease-in-out;
  color: $white;
  padding: 8px 22px;
  
}
.e_solid_green{ 
  background: $green;
  &:hover {
    background: $dark_green;
  }
 
}
.e_solid_red{
  background: $red;
  &:hover {
    background: $dark_red;
  }
}
button[disabled]{
  opacity: 0.7;
  pointer-events: none;
}