@import "./../config.scss";

$md-radio-border-color: #fff;
$md-radio-size: 18.78px;
$md-radio-checked-size: 8px;

.e_radiobutton {
  margin: 14px 0;

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $primary_color;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0px ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    @extend %font14;
    font-weight: 400 !important;
    // color: $white !important;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:hover {
      color: $primary_color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 1px solid $md-radio-border-color;
    }

    &:after {
      top: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
      left: calc($md-radio-size / 2) - calc($md-radio-checked-size / 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $primary_color;
    }
  }
  .radioButton_e_radiobutton__gL4Sj label:before {
    width: 22px;
    height: 22px;
  }
  .radioButton_e_radiobutton__gL4Sj label:after {
    display: inline-block;
    width: 10px;
    height: 10px;
  }
}

.e_v2r_radiobutton {
  margin: 14px 0;

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $primary_color;
    }

    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 0px 20px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    @extend %font14;
    color: $white !important;
    font-weight: 400 !important;
    // color: $white !important;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:hover {
      color: $primary_color;
    }

    &:before {
      left: 0;
      top: 2px;
      width: 14px;
      height: 14px;
      border: 1px solid $md-radio-border-color;
    }

    &:after {
      top: 6px;
      left: 4px;
      width: 6px;
      height: 6px;
      transform: scale(0);
      background: $primary_color;
    }
  }
  .radioButton_e_v2r_radiobutton__tlZQN label:before {
    width: 14px;
    height: 14px;
  }
  .radioButton_e_v2r_radiobutton__tlZQN label:after {
    display: inline-block;
    width: 6px;
    height: 6px;
  }
}

.e_disabled_radio {
  label {
    opacity: 0.4;
    cursor: no-drop;
  }
}
