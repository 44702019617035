/*
 *   File : profile.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : Profile menu
 *   Integrations : null
 *   Version : 1.0
 *   Created : 25-01-2022
 */

@import "./../config.scss";

.e_menu_dropdown {
	.dropdown {
		position: relative;
	}

	.e_logout_div {
		position: absolute;
		z-index: 3;
		right: 0;
		top: 8px;
		width: 285px;
		height: 645px;
		background: $menu_bg;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
		border-radius: 24px;
		padding: 24px 30px;

		.e_border {
			border-top: 2px solid #2b213a;
		}

		.e_user_img_wrapper {
			height: 41px;
			width: 41px;
			border-radius: 100px;
			background: $primary_gradient;

			.e_user_img {
				height: 38px;
				width: 38px;
				border-radius: 100px;
				object-fit: cover;
				margin-top: 2px;
			}
		}

		.e_profile_bg {
			h4 {
				@extend %font18;
				line-height: 20px;
				font-family: $font-secondary;
				font-weight: 700;
				margin-bottom: 5px;
				color: $white;
			}

			.e_location {
				font-weight: 400;
				font-size: 12px;
				line-height: 20px;
				color: #7d7688;
			}

			p {
				font-weight: 500;
				@extend %font14;
				line-height: 12px;
				//  color: $input_placeholder;
			}
		}
	}

	a,
	.e_link {
		text-decoration: none;
		color: $white;
		font-weight: 500;
		@extend %font14;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			color: $primary_color;
		}
	}

	.e_rating {
		color: $placeholder;
		@extend %font12;
		font-weight: 700;
	}
	.e_menu_links_wrap {
		height: 450px;
		overflow-y: auto;
	}
	.e_refer_icon {
		width: 21px;
		// opacity: 0.4;
	}
}

// Mobile
.e_menu_dropdown_mob {
	.dropdown {
		position: relative;
	}

	.e_logout_div {
		position: absolute;
		z-index: 3;
		right: 0;
		top: 8px;
		width: 285px;
		height: 670px;
		background: $menu_bg;
		box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
		border-radius: 24px;
		padding: 24px 30px;

		.e_border {
			border-top: 2px solid #2b213a;
		}

		.e_profile_bg {
			h4 {
				@extend %font18;
				line-height: 20px;
				font-family: $font-secondary;
				font-weight: 700;
				margin-bottom: 5px;
				color: $white;
			}

			.e_location {
				font-weight: 400;
				font-size: 12px;
				line-height: 20px;
				color: #7d7688;
			}

			p {
				font-weight: 500;
				@extend %font14;
				line-height: 12px;
				//  color: $input_placeholder;
			}
		}
	}

	a,
	.e_link {
		text-decoration: none;
		color: $white;
		font-weight: 500;
		@extend %font14;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			color: $primary_color;
		}
	}

	.e_rating {
		color: $placeholder;
		@extend %font12;
		font-weight: 700;
	}
	.e_refer_icon {
		width: 21px;
		opacity: 0.4;
	}
}

.e_menu_drop_down_menu {
	.e_user_img_wrapper {
		height: 41px;
		width: 41px;
		border-radius: 100px;
		background: $primary_gradient;

		.e_user_img {
			height: 38px;
			width: 38px;
			border-radius: 100px;
			object-fit: cover;
			margin-top: 2px;
		}
	}
	.e_profile_bg {
		h4 {
			@extend %font18;
			line-height: 20px;
			font-family: $font-secondary;
			font-weight: 700;
			margin-bottom: 5px;
			color: $white;
		}

		.e_location {
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			color: #7d7688;
		}

		p {
			font-weight: 500;
			@extend %font14;
			line-height: 12px;
			//  color: $input_placeholder;
		}
	}

	a,
	.e_link {
		text-decoration: none;
		color: $white;
		font-weight: 500;
		@extend %font14;
		line-height: 32px;
		cursor: pointer;

		&:hover {
			color: $primary_color;
		}
	}

	.e_rating {
		color: $placeholder;
		@extend %font12;
		font-weight: 700;
	}
	.e_refer_icon {
		width: 21px;
		opacity: 0.4;
	}
}

// .e_dropdown_game {
//   .game-dropdown {
//     position: relative;
//   }
//   .e_game_div {
//     position: absolute;
//     z-index: 3;
//     right: 99%;
//     top: 37%;
//     width: 185px;
//     height: 180px;
//     background: $menu_bg;
//     // background: $white;
//     box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
//     border-radius: 24px;
//     padding: 25px;
//     .e_border {
//       border-top: 2px solid #2b213a;
//     }
//   }
// }

.e_menu_active {
	color: $primary_color !important;
}
.e_menu {
	span {
		color: #ffffff;
	}
}
