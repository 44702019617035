@import "./../config.scss";

.e_slot_modal {
  background-color: #0c031b;
  width: 235px;
  min-height: 176px;
  position: absolute;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  left: 0;
  top: 30px;
  z-index: 10;
  padding: 24px;
  span {
    display: block;
    color: $white;
    font-weight: 400;
    @extend %font14;
    line-height: 34px;
    &:hover {
      opacity: 0.7;
    }
  }
  p {
    font-weight: 600;
    @extend %font14;
    line-height: 120%;
    color: $placeholder;
  }
}
.e_option_wrapper {
  max-height: 400px;
  overflow: auto;
}

.e_game_type_modal_home {
  background-color: $secondary_color;
  width: 186px;
  // height: 176px;
  position: absolute;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  left: 0;
  top: 30px;
  z-index: 999;
  span {
    display: block;
    color: $white;
    font-weight: 200;
    font-size: 14px;
    line-height: 32px;
  }
  .e_active {
    color: $primary_color !important;
  }
}
