/*
 *   File : textLink.Text.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Link Text Button Style
 *   Integrations : null
 *   Version : v1.0
*   Created : 14-01-2022
 */

@import "./../config.scss";

.e_linktxt_btn{
  color: $primary_color;
  font-weight: 600;
  @extend %font14;
  background: none;
  border: none;
  outline: none;
  line-height: 150%;
  position: relative;
  transition: background-size 0.3s linear;
  text-decoration: none;
  background: linear-gradient(to right, #9C1FE9 0%,#9C1FE9 98%);
  background-size: 0px 2px;
  background-repeat: no-repeat;
  background-position: left 85%;
  padding: 0 0 7px 0;
  cursor: pointer;
  &:hover {
    background-size: 100% 2px;
  }
}
.e_icon_l_link {
  margin-right: 10px;
  position: relative;
  bottom: 1px;
}
.e_icon_r_link {
  margin-left: 10px;
  position: relative;
  bottom: 1px;
}