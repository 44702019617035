/*
 *   File : game-result.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Game Detail Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 23-03-2022
 */

@import "./../config.scss";

.e_game_result_modal {
  .e_modal_dialog {
    max-width: 1078px !important;
    // min-height: 744px!important;
  }
  .e_modal_content {
    padding: 25px 10px !important;
    border-radius: 32px !important;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 1rem !important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    h6 {
      color: $white;
      font-family: $font-primary;
    }
    p {
      @extend %font14;
      line-height: 120%;
      padding-top: 12px;
      color: $placeholder;
      font-weight: 400;
      margin-bottom: 0;
    }
    .e_result_table {
      box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      background: #1f1136;
      padding: 12px 16px;
      border: 1px solid $primary_color;
    }
  }
  .e_game_result_modal_tab {
    color: $white;
    font-size: 14px;
    font-weight: 500 !important;
    font-family: $font-secondary;
  }
  .e_inactive {
    opacity: 0.6;
  }
  .e_total_qty {
    background: #0d021e;
    color: #fff;
  }
}

.e_v2r_game_result_modal {
  .e_modal_dialog {
    max-width: 1078px !important;
    // min-height: 744px!important;
  }
  .e_modal_content {
    padding: 25px 10px !important;
    border-radius: 32px !important;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 1rem !important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    h6 {
      color: $white;
      font-family: $font-primary;
      @extend %font14;
    }
    p {
      @extend %font14;
      line-height: 120%;
      padding-top: 12px;
      color: $placeholder;
      font-weight: 400;
      margin-bottom: 16px !important;
    }
    .e_result_table {
      box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      background: #1f1136;
      padding: 12px 16px;
      border: 1px solid $primary_color;
    }
  }
  .e_game_result_modal_tab {
    color: $white;
    font-size: 14px;
    font-weight: 500 !important;
    font-family: $font-secondary;
  }
  .e_inactive {
    opacity: 0.6;
  }
  .e_total_qty {
    background: #0d021e;
    color: #fff;
  }
  .e-table-header {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .e-table-body {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
}
