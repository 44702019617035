/*
 *   File : input.module.scss
 *   Author : https://evoqueinnovativelab.com
 *   Description : InputBox style
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

 @import "./../config.scss";

.e_input_box{
  @extend %font14;
  background: $secondary_color;
  border: 2px solid transparent;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $white;
  padding: 18px 29px 18px 16px;
  border-radius: 8px;
  &::placeholder{
    font-weight: 400;
    line-height: 18px;
    color: $placeholder;
    @extend %font14;
   
  }
}
input:focus {
  // border-image-source: linear-gradient(90deg, #9C1FE9 0%, #4A06B8 100%);
  // border-width: 2pt;
  // border-image-slice: 1;
  // border: 2px solid linear-gradient(90deg, #9C1FE9 0%, #4A06B8 100%)!important;
  
  // box-shadow: 4px 4px 24px rgba(154, 30, 232, 0.48);
  border: 2px solid $primary_color!important;
}
.e_input_error{
  border: 2px solid $error;

}
.e_error_msg{
  @extend %font12;
  color: $error !important;
}
label {
  font-weight: 400;
  @extend %font12;
  line-height: 150%;
  color: $white;
  font-family: $font-primary;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.e_suffix{
  color: $white;
  position: absolute;
  top: 33%;
  right: 6%;
  font-size: 14px;
}
