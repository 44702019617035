/*
 *   File : phoneInput.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Phone Input style
 *   Integrations :
 *   Version : v1.0
 */

@import "./../config.scss";

.PhoneInput{
  background-color: $secondary_color!important;
  border: 2px solid $secondary_color;
  // background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: 400;
  // color: $white;
  border-radius: 8px;
  padding: 18px 16px;
  font-family: $font-primary;

  &::focus{
    border: 2px solid $primary_color;
  }
}
.PhoneInput--focus::focus{
  border: 2px solid $primary_color;
}
.PhoneInput--focus{
  background-color: $secondary_color!important;
  border: 2px solid $primary_color;
  outline: none!important;
  &::placeholder{
  font-weight: 400;
  @extend %font14;
  line-height: 18px;
  // color: $white;
  }
}
.PhoneInput{
  input{
    background-color: $secondary_color!important;
    border: none!important;
    outline: none!important;
    font-weight: 400;
    @extend %font14;
    color: $white;
    &::placeholder{
      font-weight: 400;
      line-height: 18px;
      color: $placeholder;
      @extend %font12;
    }
    &:focus{
      border: none!important;
      outline: none!important;
    }

  }
}
.e_input_error{
  border: 1px solid $error;

}
.e_error_msg{
  @extend %font12;
  color: $error !important;
}
// .dropdownStyle {
//   font-family: $font-primary;
//   font-size: 14px!important;
// }

