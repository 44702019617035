
@import "./../config.scss";

.e_preference_modal{
    .e_modal_content{
      min-height: 300px!important;
    }
    .e_modal_dialog {
        max-width: 500px!important;
      }
      .e_modal_header {
        border-bottom: none!important;
        // padding: 0!important;
        h4 {
         color: $white;
         font-size: 18px;
          font-family: $font-secondary;
        }
      }
      .e_bank_card{
          border-radius: 16px;
          background-color: #130727;
          cursor: pointer;
      }
      .e_radio_class{
        margin: 10px 0px 14px 0!important;
      }
}