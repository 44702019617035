/*
 *   File : game-detail.module.scss
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Game Detail Modal Style
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-01-2022
 */

@import "./../config.scss";

.e_game_detail_modal {
  .e_modal_dialog {
    max-width: 1078px !important;
    // min-height: 744px!important;
  }
  .e_modal_content {
    padding: 25px 10px !important;
    border-radius: 32px !important;
    .e_modal_header {
      border-bottom: none !important;
      padding: 0 1rem !important;
      h4 {
        @extend %h4;
        font-family: $font-secondary;
      }
    }
    .e_input_bg {
      background: $modal_bg;
    }
    .e_content_wrapper {
      background-color: $modal_bg;
      border-radius: 24px;
      padding: 24px;
      height: 70vh;
      overflow-y: auto !important;
      &::-webkit-scrollbar {
        display: none;
      }

      li,
      p {
        color: $white;
        font-weight: 400;
        @extend %font16;
        line-height: 150%;
      }
      .e_arrow {
        position: fixed;
        right: 20%;
        top: 80%;
      }

      .e_btn {
        border: none;
        outline: none;
        background: $primary_gradient;
        color: $white;
        font-weight: 600;
        @extend %font16;
        line-height: 150%;
        border-radius: 8px;
        padding: 18px 28px;
        &:hover {
          background: $primary_hover;
          transition: 0.4s ease-in-out;
          // box-shadow: 2px 2px 48px rgba(194,134,231);
          box-shadow: 4px 4px 24px rgba(154, 30, 232, 0.48);
        }
      }
      .e_demo_btn {
        border: none;
        outline: none;
        color: $white;
        background: #1f1136;
        font-weight: 600;
        @extend %font16;
        line-height: 150%;
        border-radius: 8px;
        padding: 18px 28px;

        img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
      }

      .e_game_wrapper {
        background-color: $blue;
        padding: 24px 24px 24px 70px;
        border-radius: 32px;
        color: $white;
        h2 {
          @extend %font48;
          color: $white;
          font-weight: 800;
          font-family: $font-secondary;
        }
        h6 {
          font-weight: 300;
          @extend %font14;
          font-family: $font-secondary;
          color: $white;
        }
        span {
          font-weight: 800;
          @extend %font18;
        }
      }
      .e_game_wrapper_m2m {
        background-color: #9085df;
        padding: 24px 24px 24px 70px;
        border-radius: 32px;
        color: $white;
        h2 {
          @extend %font48;
          color: $white;
          font-weight: 800;
          font-family: $font-secondary;
        }
        h6 {
          font-weight: 300;
          @extend %font14;
          font-family: $font-secondary;
          color: $white;
        }
        span {
          font-weight: 800;
          @extend %font18;
        }
      }
      .e_game_wrapper_skill {
        // background-color: $yellow;
        padding: 24px 24px 24px 70px;
        border-radius: 32px;
        color: $white;
        h2 {
          @extend %font48;
          color: $white;
          font-weight: 800;
          font-family: $font-secondary;
        }
        h6 {
          font-weight: 300;
          @extend %font14;
          font-family: $font-secondary;
          color: $white;
        }
        span {
          font-weight: 800;
          @extend %font18;
        }
      }
    }
  }
}
.e_check_wrapper {
  padding-left: 60px !important;
}
.e_check {
  top: 5px;
  // left: 40%;
  color: #fff !important;
}
.e_arrow_wrap {
  width: 30%;
  height: 50%;
  right: 0;
  bottom: 0;
  background-color: transparent;
  div {
    height: 100%;
    width: 100%;
    .e_arrow_content {
      position: absolute;
      right: 10%;
      bottom: 10%;
    }
  }
}
.e_watch_btn {
  border: 1px solid $primary_color;
  padding: 7px 9px;
  border-radius: 8px;
  &:hover {
    background-size: 0;
    opacity: 0.8;
  }
}
